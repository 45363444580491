export const SELECTED_PROJECT_FILTERS = 'selectedProjectFilters';
export const SELECTED_TAG_FILTERS = 'selectedTagFilters';

export const parseSelectedProjectIds = (): string[] => {
    const selectedProjectIds = localStorage.getItem(SELECTED_PROJECT_FILTERS);
    return selectedProjectIds ? JSON.parse(selectedProjectIds) : [];
}

export const parseSelectedTagIds = (): string[] => {
    const selectedTagIds = localStorage.getItem(SELECTED_TAG_FILTERS);
    return selectedTagIds ? JSON.parse(selectedTagIds) : [];
}

export const doSelectedProjectIdsExist = (): boolean => {
    const projects = localStorage.getItem(SELECTED_PROJECT_FILTERS);
    return !!projects;
}

export const doSelectedTagIdsExist = (): boolean => {
    const tags = localStorage.getItem(SELECTED_TAG_FILTERS);
    return !!tags;
}

export const storeSelectedProjectIds = (selectedProjectIds: string[]) => {
    localStorage.setItem(SELECTED_PROJECT_FILTERS, JSON.stringify(selectedProjectIds));
}

export const storeSelectedTagIds = (selectedTagIds: string[]) => {
    localStorage.setItem(SELECTED_TAG_FILTERS, JSON.stringify(selectedTagIds));
}