
export enum TagType {
    Workstream = "workstream"
}

export interface Tag {
    id?: string
    name: string
    type: TagType
    creator?: string
    createdDate?: Date
    updatedDate?: Date
}