import * as React from 'react';
import { Badge, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TooltipIcon } from './tooltipIcon';

interface TooltipIconWithBadgeProps {
    titleKey: string;
    icon: JSX.Element;
    badgeContent: string | number;
}


export const TooltipIconWithBadge: React.FC<TooltipIconWithBadgeProps> = (props: TooltipIconWithBadgeProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Badge badgeContent={props.badgeContent} color="secondary" overlap="circular"
            sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }}>
            <TooltipIcon titleKey={props.titleKey} icon={props.icon} />
        </Badge>
    );
}