import { QueryOptions } from "@testing-library/react";
import config from "../config"
import { Project, ProjectFilters } from "../models/project";
import { ProjectService } from "../services/projectService";
import { ActionMethod, PayloadAction, createPayloadAction } from "./actionCreators";
import { Dispatch } from "react";
import { ActionTypes } from "./common";

const service = new ProjectService(config.api.baseUrl, '/projects');

export interface ProjectActions {
    list(options?: QueryOptions): Promise<Project[]>
    save(list: Project): Promise<Project>
    remove(id: string): Promise<void>
    setSelectedProjectFilters(filters: ProjectFilters): Promise<ProjectFilters>
}

export const save = async (project: Project): Promise<Project> => {
    return await service.save(project);
}

export const remove = async (id: string): Promise<void> => {
    await service.delete(id);
}

export const list = async (options?: QueryOptions): Promise<Project[]> => {
    return await service.getList(options);
}

export const setSelectedProjectFilters = (filters: ProjectFilters): ActionMethod<ProjectFilters> => async (dispatch: Dispatch<SetSelectedProjectAction>) => {
    dispatch(setSelectedProjectFiltersAction(filters));
    return filters;
}

export interface SetSelectedProjectAction extends PayloadAction<string, ProjectFilters | undefined> {
    type: ActionTypes.SET_SELECTED_PROJECT_FILTERS
}

export const setSelectedProjectFiltersAction = createPayloadAction<SetSelectedProjectAction>(ActionTypes.SET_SELECTED_PROJECT_FILTERS);