import { QueryOptions } from "@testing-library/react";
import { ApiUser } from "../models";
import config from "../config"
import { UserService } from "../services/userService";
import { ActionTypes } from "./common";
import { Action, ActionMethod, PayloadAction, createAction, createPayloadAction } from "./actionCreators";
import getAxiosFactory from "../services/customAxios";
import { Dispatch } from "react";

const userService = new UserService(config.api.baseUrl, '/users');

export interface UserActions {
    list(options?: QueryOptions): Promise<ApiUser[]>
    save(list: ApiUser): Promise<ApiUser>
    remove(id: string): Promise<void>
    getInvoker(): Promise<ApiUser>
    clearInvoker(): Promise<void>
}

export const list = async (options?: QueryOptions): Promise<ApiUser[]> => {
    return await userService.getList(options);
}

export const clearInvoker = (): ActionMethod<void> => async (dispatch: Dispatch<ClearInvokerAction>) => {
    dispatch(clearInvokerAction());
}

export const getInvokerUtility = async (): Promise<ApiUser> => {
    const axiosFactory = getAxiosFactory(config.api.baseUrl, '/users/invoker');
    const realClient = await axiosFactory();
    const response = await realClient.request<ApiUser>({
        method: 'GET'
    });
    return response.data;
}

export const getInvoker = (): ActionMethod<ApiUser> => async (dispatch: Dispatch<SetInvokerAction>) => {
    const invoker = await getInvokerUtility();
    dispatch(setInvokerAction(invoker));
    return invoker;
}

export const save = async (user: ApiUser): Promise<ApiUser> => {
    return await userService.save(user);
}

export const remove = async (id: string): Promise<void> => {
    return await userService.delete(id);
}

export interface SetInvokerAction extends PayloadAction<string, ApiUser | undefined> {
    type: ActionTypes.SET_INVOKER_USER
}

export interface ClearInvokerAction extends Action<string | undefined> {
    type: ActionTypes.CLEAR_INVOKER_USER
}

export const setInvokerAction = createPayloadAction<SetInvokerAction>(ActionTypes.SET_INVOKER_USER);
export const clearInvokerAction = createAction<ClearInvokerAction>(ActionTypes.CLEAR_INVOKER_USER);