import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import ScheduleIcon from '@mui/icons-material/Schedule';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TaskIcon from '@mui/icons-material/Task';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { UserRole } from "../models";
import FolderIcon from '@mui/icons-material/Folder';
import ListIcon from '@mui/icons-material/List';
import React from "react";
import { AppContext } from "../models/applicationState";
import { Context } from "../components/context";

const navLinks = [
    {
        index: 1,
        name: 'menu.leadership',
        url: "/leadership",
        icon: 'LeadershipIcon',
        roles: [UserRole.Admin, UserRole.Creator, UserRole.Actor]
    },
    // {
    //     index: 2,
    //     name: 'menu.my_calendar_events',
    //     url: "/events",
    //     icon: 'ScheduleIcon',
    //     roles: [UserRole.Admin, UserRole.Creator]
    // },
    {
        index: 3,
        name: 'menu.create_meeting_note',
        url: "/meetings",
        icon: 'EventNoteIcon',
        roles: [UserRole.Admin, UserRole.Creator]
    },
    {
        index: 4,
        name: 'menu.meeting_minutes_library',
        url: "/library",
        icon: 'LibraryBooksIcon',
        roles: [UserRole.Admin, UserRole.Creator, UserRole.Actor]
    },
    {
        index: 5,
        name: 'menu.actor_dashboard',
        url: "/dashboard/actor",
        icon: 'TaskIcon',
        roles: [UserRole.Admin, UserRole.Creator, UserRole.Actor]
    },
    {
        index: 6,
        name: 'menu.creator_dashboard',
        url: "/dashboard/creator",
        icon: 'DashboardIcon',
        roles: [UserRole.Admin, UserRole.Creator]
    },
    {
        index: 7,
        name: 'menu.manage_projects',
        url: "/projects",
        icon: 'FolderIcon',
        roles: [UserRole.Admin]
    },
    {
        index: 8,
        name: 'menu.manage_roles',
        url: "/users",
        icon: 'AdminPanelSettingsIcon',
        roles: [UserRole.Admin]
    }
];

const getNavIcon = (icon: string): ReactElement => {
    switch (icon) {
        case 'LeadershipIcon':
            return <QueryStatsIcon />
        case 'ScheduleIcon':
            return <ScheduleIcon />
        case 'EventNoteIcon':
            return <EventNoteIcon />
        case 'LibraryBooksIcon':
            return <MenuBookIcon />
        case 'TaskIcon':
            return <TaskIcon />
        case 'DashboardIcon':
            return <DashboardIcon />
        case 'AdminPanelSettingsIcon':
            return <AdminPanelSettingsIcon />
        case 'FolderIcon':
            return <FolderIcon />
        case 'ListIcon':
            return <ListIcon />
        default:
            return <div />;
    }
}

interface NavProps {
    open: boolean
}
const setActiveNav = (path: string): number => {
    const active = navLinks.filter(({ url }) => path.startsWith(url));
    return active[0]?.index;
}

const MainNav: FC<NavProps> = (props: NavProps): ReactElement => {
    const navigate = useNavigate();
    const theme = useTheme();

    const { t } = useTranslation();
    const location = useLocation();

    const [filteredNavLinks, setFilteredNavLinks] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(setActiveNav(location.pathname) || undefined);

    const appContext = React.useContext<AppContext>(Context);

    useEffect(() => {
        setFilteredNavLinks(navLinks.filter(l => l.roles.includes(appContext.state.invoker?.role)));
    }, [appContext.state.invoker]);

    useEffect(() => {
        setSelectedIndex(setActiveNav(location.pathname));
    }, [navigate]);

    const handleListItemClick = (event: any, selectedIndex: number) => {
        event.preventDefault();
        setSelectedIndex(selectedIndex);
        const selection = navLinks.filter(({ index }) => index === selectedIndex)[0];
        navigate(selection ? selection.url : "/")
    };

    return (
        <List component="nav">
            {filteredNavLinks.map(({ index, name, icon }) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: props.open ? 'initial' : 'center',
                            px: 2.5,
                            ":hover": {
                                backgroundColor: theme.palette.action.hover
                            },
                            "&.Mui-selected": {
                                backgroundColor: theme.palette.action.selected,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.focus
                                }
                            }
                        }}
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: props.open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {getNavIcon(icon)}
                        </ListItemIcon>
                        <ListItemText primary={t(name)} sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>))
            }
        </List>
    );
}
export default MainNav;