export interface ApiConfig {
    baseUrl: string
}

export interface WebConfig {
    baseUrl: string
}

export interface ObservabilityConfig {
    connectionString: string
}

export interface AppConfig {
    web: WebConfig
    api: ApiConfig
    observability: ObservabilityConfig
}

export const efesoDomain = 'efeso.pinnacle.ceo';
export const rtDomain = 'rt.pinnacle.ceo';

const hostName = window.location.hostname;
const baseUrl = hostName === 'localhost' ? 'http://localhost:3000' : `https://${hostName}`;

const config: AppConfig = {
    web: {
        baseUrl: baseUrl // || window.ENV_CONFIG.REACT_APP_WEB_BASE_URL || 'http://localhost:3000'
    },
    api: {
        baseUrl: window.ENV_CONFIG.REACT_APP_API_BASE_URL || 'http://localhost:3100'
    },
    observability: {
        connectionString: window.ENV_CONFIG.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING || ''
    }
}

export default config;