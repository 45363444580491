import { GridColDef } from "@mui/x-data-grid";

export const emptyProjectRepresentation = {
    id: "434353",
    name: "",
    users: [],
    tags: [],
    disconnectedTags: []
}

export const nameColumn: GridColDef = { field: 'name', headerName: 'meeting.project', editable: true, display: 'flex', width: 400 };
export const tagNameColumn: GridColDef = { field: 'name', headerName: 'project.tag', editable: true, display: 'flex', width: 400 };

export const usersColumn: GridColDef = {
    field: 'users',
    headerName: 'project.users',
    width: 500,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            row.users = value;
            return row;
        }
        return row;
    },
    display: 'flex'
};

export const tagsColumn: GridColDef = {
    field: 'tags',
    headerName: 'project.tags',
    width: 500,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            row.tags = value;
            return row;
        }
        return row;
    },
    display: 'flex'
};

export const templateColumn = {
    field: 'isTemplate',
    headerName: 'project.isTemplate',
    width: 70,
    editable: false,
    // align: 'center',
    // headerAlign: 'center'
    display: 'flex'
}