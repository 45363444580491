import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState } from 'react';
import { TextField } from "@mui/material";

const Login: React.FC = () => {
  const { instance } = useMsal();
  const [email, setEmail] = useState('');

  const redirectAsync = async () => {

    const enterpriseDomains = ['wdhnt.onmicrosoft.com'];
    // TODO: do this check on the server side vs email

    if (enterpriseDomains.some(d => email.endsWith(d))) {
      instance.getConfiguration().auth.authority = `https://login.microsoftonline.com/common`;
      // instance.getConfiguration().auth.supportsNestedAppAuth
      console.info(`CONFIGURED MSAL TO AUTHORITY ${instance.getConfiguration().auth.authority}`);
    }

    instance
      .loginRedirect({ ...loginRequest, loginHint: email })
      .catch((error) => console.log(error));
  };

  const handleRedirect = () => {
    redirectAsync();
  };

  const getLanguage = () => window.localStorage.i18nextLng || navigator.language || 'en';

  return (
    <Box>
      <CssBaseline />
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        gap={4}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.background.paper
              : theme.palette.grey[900],
          overflow: 'auto',
        }}
      >
        {/* <Container maxWidth={false} sx={{ mt: 0, mb: 0 }}> */}
        <Box
          component="img"
          sx={{
            width: '30vw',
            marginBottom: 2
          }}
          alt="Pinnacle logo"
          src='/logo.svg'
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" display="block">
            {getLanguage().startsWith('de') ? "Führungskräfte stärken" : "Empowering Leaders"}
          </Typography>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginTop: 5, marginBottom: 1, width: 460 }}
          />
          <Button variant="contained" onClick={handleRedirect} disabled={!email} sx={{ width: 460 }}>
            <Typography variant="button" display="block">
              {getLanguage().startsWith('de') ? "Einloggen" : "Sign in"}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
