
import IconButton from "@mui/material/IconButton";
import { FC, ReactElement } from "react"
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface MeetingHeaderProps {
    meetingName?: string
    primaryBtnText: string
    disablePrimaryBtn?: boolean
    onPrimaryBtnClick: () => void
    secondaryBtnText?: string
    disableSecondaryBtn?: boolean
    onSecondaryBtnClick?: () => void
}

export const MeetingHeader: FC<MeetingHeaderProps> = (props: MeetingHeaderProps): ReactElement => {
    const navigate = useNavigate();
    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1, marginBottom: 1 }}>
            <IconButton onClick={() => navigate(-1)}>
                <ChevronLeftIcon />
            </IconButton>
            <Typography variant="h6" sx={{ marginRight: 1 }}>
                {props.meetingName}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="outlined" onClick={props.onPrimaryBtnClick} disabled={props.disablePrimaryBtn}>
                <Typography variant="button" display="block">
                    {props.primaryBtnText}
                </Typography>
            </Button>
            {props.secondaryBtnText &&
                <Button variant="outlined" onClick={props.onSecondaryBtnClick} disabled={props.disableSecondaryBtn}>
                    <Typography variant="button" display="block">
                        {props.secondaryBtnText}
                    </Typography>
                </Button>
            }
        </Box>
    );
}

