import { MeetingItem } from "./meetingItem";

export enum MeetingStatus {
    Draft = "draft",
    InReview = "inreview",
    Reviewed = "reviewed",
    Published = "published"
}

export interface Meeting {
    id?: string
    name: string
    status?: MeetingStatus
    date?: Date
    creator?: string
    index?: number
    identifier?: string
    reviewer?: string
    participants?: string[]
    viewers?: string[]
    items?: MeetingItem[]
    attachments?: number
    createdDate?: Date
    updatedDate?: Date
    project?: string
    defaultTags?: string[]
    inReviewOn?: Date
    publishedOn?: Date
    archived?: boolean
}