import { ApiUser } from "../models/user";
import getAxiosFactory from "./customAxios";
import { RestService } from "./restService";
import config from "../config";

export class UserService extends RestService<ApiUser> {
    public constructor(baseUrl: string, baseRoute: string) {
        super(baseUrl, baseRoute);
    }
}

export const bulkCreateUsers = async (users?: ApiUser[]) => {
    const url = `/users/bulk/`;
    const factory = await getAxiosFactory(config.api.baseUrl, url)();
    const response = await factory.request({
        method: 'POST',
        data: users
    });
    return response.data;
}

export const acceptTerms = async () => {
    const url = `/users/terms`;
    const factory = await getAxiosFactory(config.api.baseUrl, url)();
    const response = await factory.request({
        method: 'POST'
    });
    return response.data;
}