import {
  useContext,
  useEffect,
  useState,
} from "react";
import { ApiUser } from "../../models";
import * as userActions from "../../actions/userActions";
import { withApplicationInsights } from "../../components/telemetry";
import { useNavigate } from "react-router-dom";
import CrudGrid from "../../components/crudGrid";
import { commentsColumn, createdAtColumn as createdDateColumn, createdByColumn, emailColumn, emptyUserRepresentation, firstNameColumn, lastNameColumn, organizationColumn, roleColumn } from "../../models/userRepresentation";
import { Box, CssBaseline } from "@mui/material";

const UsersPage = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const loadUsers = async () => {
    const users = await userActions.list();
    await setUsers(users);
  }

  useEffect(() => {
    loadUsers();
  }, [navigate, refreshData]);

  const onItemUpserted = async (newUser: ApiUser, _oldItem?: ApiUser) => {
    await userActions.save(newUser);
    setRefreshData(!refreshData);
  }

  const onItemDeleted = async (user: ApiUser) => {
    if (user.id === emptyUserRepresentation.id) {
      console.info(`Returning on user upserted, this should not happen ${user.id}`);
      return;
    }
    await userActions.remove(user.id);
    setRefreshData(!refreshData);
  }

  return (
    <Box>
      <CssBaseline />
      <CrudGrid
        processRowUpdate={onItemUpserted}
        onDelete={onItemDeleted}
        rows={users}
        columns={[emailColumn, roleColumn, firstNameColumn, lastNameColumn, organizationColumn, commentsColumn, createdByColumn, createdDateColumn]}
        height='85vh'
        emptyRow={emptyUserRepresentation}
        modelType={"user"} topColumns={[emailColumn, roleColumn, firstNameColumn, lastNameColumn, organizationColumn, commentsColumn]}      />
    </Box>
  );
};

export default withApplicationInsights(UsersPage, "UsersPage");
