import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import MeetingsPage from '../pages/notes/meetingsPage';
import Landing from '../pages/landing/landingPage';
import MeetingLibraryPage from '../pages/library/meetingLibraryPage';
import ItemsPage from '../pages/notes/itemsPage';
import ActorDashboard from '../pages/dashboards/actorDashboardPage';
import CreatorDashboard from '../pages/dashboards/creatorDashboardPage';
import UsersPage from '../pages/management/usersPage';
import { ReviewPage } from '../pages/notes/reviewPage';
import MainHeader from './mainHeader';
import MainNav from './mainNav';
import { useEffect } from 'react';
import { bindActionCreators } from '../actions/actionCreators';
import { ItemActions } from '../actions/itemActions';
import { ListActions } from '../actions/listActions';
import { Context } from '../components/context';
import { AppContext } from '../models/applicationState';
import * as itemActions from '../actions/itemActions';
import * as listActions from '../actions/listActions';
import MeetingLibraryItemsPage from '../pages/library/meetingLibraryItemsPage';
import ProjectPanel from '../components/projectPanel';
import ProjectsPage from '../pages/management/projectsPage';
import * as userActions from '../actions/userActions';
import { useMsal } from '@azure/msal-react';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { UserActions } from '../actions/userActions';
import { doSelectedProjectIdsExist, doSelectedTagIdsExist, storeSelectedProjectIds } from '../util/filterHandler';
import { createTheme, ThemeProvider, darken, lighten } from '@mui/material/styles';
import { efesoDomain, rtDomain } from '../config';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { acceptTerms } from '../services/userService';
import { grey } from '@mui/material/colors';

const drawerWidth = 260;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.background.default,
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function Layout() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [subscribedToInstance, setSubscribedToInstance] = React.useState(false);
    const [loggedInUser, setLoggedInUser] = React.useState(undefined);
    const appContext = React.useContext<AppContext>(Context);

    const actions = React.useMemo(() => ({
        lists: bindActionCreators(listActions, appContext.dispatch) as unknown as ListActions,
        items: bindActionCreators(itemActions, appContext.dispatch) as unknown as ItemActions,
        users: bindActionCreators(userActions, appContext.dispatch) as unknown as UserActions
    }), [appContext.dispatch]);

    const getInvokerUser = async () => {
        actions.users.getInvoker();
    }


    useEffect(() => {
        if (!loggedInUser || loggedInUser != appContext.state.invoker?.email) {
            getInvokerUser();
        }
    }, [loggedInUser, appContext.state.invoker]);

    useEffect(() => {
        if (appContext.state.invoker?.projects?.length) {
            if (!doSelectedProjectIdsExist() && !doSelectedTagIdsExist()) {
                storeSelectedProjectIds([]);
                setOpenProjectPanel(true);
            }
        }
    }, [appContext.state.invoker?.projects]);


    useEffect(() => {
        if (!subscribedToInstance) {
            console.info(`Adding listener to ${instance}`);
            instance?.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const payload = event.payload as AuthenticationResult;
                    setLoggedInUser(payload?.account?.username);
                } else if (event.eventType === EventType.LOGOUT_SUCCESS && event.payload) {
                    setLoggedInUser(null);
                } else if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
                    const username = (event.payload as AuthenticationResult)?.account?.username;
                    setLoggedInUser(username);
                }
            });
            setSubscribedToInstance(true);
        }
    }, [navigate]);

    const [open, setOpen] = React.useState(true);
    const [openProjectPanel, setOpenProjectPanel] = React.useState(false);

    // Load meetings
    useEffect(() => {
        if (!appContext.state.lists) {
            actions.lists.list();
        }
    }, [actions.lists, appContext.state.lists]);

    const toggleDrawer = () => {
        setOpen(prev => !prev);
    };

    const toggleProjectPanel = () => {
        setOpenProjectPanel(prev => !prev);
    };

    const hostName = window.location.hostname;

    const theme = createTheme({
        palette: {
            primary: {
                main: '#242C63'
            },
            secondary: {
                main: '#777586'
            },
            action: {
                // active: '#777586',
                // hover: lighten('#F1EAE3', 0.3),
                hover: '#F1EAE3',
                selected: darken('#F1EAE3', 0.1),
                focus: darken('#F1EAE3', 0.15)
            },
            background: {
                default: '#FFF',
                paper: '#E9ECF5',
            }
        },
    });

    const efesoTheme = createTheme({
        palette: {
            primary: {
                main: '#3B3D43'
            },
            secondary: {
                main: '#385D7F'
            },
            action: {
                hover: grey[200],
                selected: lighten('#EF7A13', 0.75),
                focus: lighten('#EF7A13', 0.5)
            },
            background: {
                default: '#FFF',
                paper: '#E9ECF5',
            },
            text: {
                primary: '#385D7F'
            }
        },
    });

    const rtTheme = createTheme({
        palette: {
            primary: {
                main: '#16116D'
            },
            secondary: {
                main: '#777586'
            },
            action: {
                hover: '#715979',
                selected: darken('#715979', 0.1),
                focus: darken('#715979', 0.15)
            },
            background: {
                default: '#FFF',
                paper: '#6DD7CD',
            }
        },
    });

    let selectedTheme = theme;
    if (hostName === efesoDomain) {
        selectedTheme = efesoTheme;
    }
    if (hostName === rtDomain) {
        selectedTheme = rtTheme;
    }

    function accept(_event): void {
        const userWithTerms = {...appContext.state.invoker, termsAcceptanceDate: new Date()}
        
        acceptTerms();
        setLoggedInUser(userWithTerms);
    }

    return (
        <ThemeProvider theme={selectedTheme}>
            {appContext.state.invoker && !appContext.state.invoker?.termsAcceptanceDate && 
             <Box>
             <CssBaseline />
             <Box
               component="main"
               display="flex"
               flexDirection="column"
               alignItems="center"
               justifyContent="center"
               height="100vh"
               gap={4}
               sx={{
                 backgroundColor: (theme) =>
                   theme.palette.mode === 'light'
                     ? theme.palette.background.paper
                     : theme.palette.grey[900],
                 overflow: 'auto',
               }}
             >
               {/* <Container maxWidth={false} sx={{ mt: 0, mb: 0 }}> */}
               <Box
                 component="img"
                 sx={{
                   width: '30vw',
                   marginBottom: 2
                 }}
                 alt="Pinnacle logo"
                 src='/logo.svg'
               />
               <Box
                 display="flex"
                 flexDirection="column"
                 alignItems="center"
                 justifyContent="center"
               >
                 <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <a target="_blank" href="/Pinnacle_TC.pdf">{t('about.terms_conditions')}</a>
                    <br />
                    {t('about.copyright')}
                </Typography>
                 
                <br />
                <br />

                 <Button variant="contained" onClick={accept} sx={{ width: 460 }}>
                   <Typography variant="button" display="block">
                     {t('about.accept_terms')}
                   </Typography>
                 </Button>
               </Box>
             </Box>
           </Box>
            }
            {appContext.state.invoker?.termsAcceptanceDate &&
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="absolute" open={open}>
                        <MainHeader
                            toggleDrawer={toggleDrawer}
                            open={open}
                            openProjectPanel={openProjectPanel}
                            showProjectPanel={!!appContext.state.invoker?.projects?.length}
                            toggleProjectPanel={toggleProjectPanel}
                        ></MainHeader>
                    </AppBar>

                    <Drawer variant="permanent" open={open}>
                        {/* {console.log("DRAWER MENU " + open)} */}
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            {/* <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton> */}
                        </Toolbar>
                        <Divider />
                        <MainNav open={open} />
                    </Drawer>

                    <Box
                        component="main"
                        // flexGrow={1}
                        width={`calc(100vw - ${drawerWidth}px)`}
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.background.paper
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            // height: '100vh',
                            // overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth={false} sx={{ mt: 0, mb: 0, height: `calc(100vh - 64px)`, overflow: 'auto' }}>
                            <Routes>
                                <Route path="/leadership" element={<Landing />} />
                                <Route path="/library" element={<MeetingLibraryPage />} />
                                <Route path="/library/:meetingId" element={<MeetingLibraryItemsPage />} />
                                <Route path="/library/:meetingId/current" element={<MeetingLibraryItemsPage />} />
                                <Route path="/meetings" element={<MeetingsPage />} />
                                <Route path="/meetings/:meetingId" element={<ItemsPage />} />
                                <Route path="/meetings/:meetingId/review" element={<ReviewPage />} />
                                {/* <Route path="/events" element={<Events />} /> */}
                                <Route path="/dashboard/actor" element={<ActorDashboard />} />
                                <Route path="/dashboard/creator" element={<CreatorDashboard />} />
                                <Route path="/users" element={<UsersPage />} />
                                <Route path="/projects" element={<ProjectsPage />} />
                                <Route path="/" element={<Navigate to="/leadership" />} />
                            </Routes>
                        </Container>
                    </Box>

                    {appContext.state.invoker?.projects?.length &&
                        <MuiDrawer
                            anchor='right'
                            open={openProjectPanel}
                        >
                            <ProjectPanel togglePanel={toggleProjectPanel} projects={appContext.state.invoker?.projects} />
                        </MuiDrawer>
                    }
                </Box>
            }
        </ThemeProvider>
    );
}