import { FC, ReactElement, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridToolbarContainer, GridTreeNodeWithRender } from "@mui/x-data-grid";
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from "react-i18next";
import { Tag, TagType } from "../models/tag";
import { Project } from "../models/project";
import { tagNameColumn } from "../models/projectRepresentation";
import { emptyTagRepresentation } from "../models/tagRepresentation";
import { randomId } from "@mui/x-data-grid-generator";
import EditableGrid from "./editableGrid";
import CancelIcon from '@mui/icons-material/Cancel';
interface TagsSelectionPanelProps {
    height: string
    project: Project
    onClose?: (project: Project) => Promise<void> | void;
    onCancel?: () => void;
}

type TagRepresentation = Tag & { isNew: boolean };

export const TagsSelectionPanel: FC<TagsSelectionPanelProps> = (props: TagsSelectionPanelProps): ReactElement => {
    const [tags, setTags] = useState<TagRepresentation[]>([]);

    useEffect(() => {
        setTags(
            (props.project.tags || []).map(t => ({ ...t, isNew: false })).filter(t => !!t.id)
        );
    }, [props.project]);

    const { t } = useTranslation();

    const translate = (col: GridColDef) => {
        return { ...col, headerName: t(col.headerName) };
    };

    const onUpserted = async (newTag: TagRepresentation, _oldTag?: TagRepresentation) => {
        if (newTag.id !== emptyTagRepresentation.id) {
            if (newTag.id) {
                setTags(tags.map(t => t.id === newTag.id ? newTag : t));
            }
        }
      }

    const onRowChanged = async (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>, row: TagRepresentation) => {
        params.api.updateRows([{ ...params.row }]);
    }

    const CustomToolbar: FC = (): ReactElement => {
        const [tagName, setTagName] = useState("");

        const onNewTag = async () => {
            setTags([...tags, {
                id:randomId(),
                name: tagName,
                type: TagType.Workstream,
                isNew: true

            }]);
            setTagName("");
        };

        return (<GridToolbarContainer>
            <TextField
                id="link_name"
                variant="standard"
                value={tagName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTagName(event.target.value || "");
                }}
                placeholder={t('project.tag')}
            ></TextField>
            <Button variant="contained" startIcon={<SaveIcon />} onClick={onNewTag}>
                <Typography variant="button" display="block">
                    {t('button.add')}
                </Typography>
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant="outlined"
                tabIndex={-1}
                startIcon={<CancelIcon />}
                onClick={props.onCancel}
            >
                {t('button.cancel')}
            </Button>
            <Button
                variant="outlined"
                tabIndex={-1}
                startIcon={<SaveIcon />}
                onClick={() => {
                    props.onClose({
                        ...props.project,
                        tags: tags.map(t => t.isNew ? { ...t, id: undefined } : t)
                    });
                }
                }
            >
                {t('button.save')}
            </Button>
        </GridToolbarContainer>);
    }

    const columns = [tagNameColumn,
        // {
        //     field: 'selected',
        //     headerName: 'tag.active',
        //     width: 200,
        //     editable: false,
        //     align: 'center',
        //     headerAlign: 'center',
        //     renderCell: (params) => (
        //         <Tooltip title={t('tag.active')}>
        //             <Checkbox
        //                 icon={<TooltipIcon titleKey={'general.select'} icon={<CheckBoxOutlineBlankIcon />} />}
        //                 checkedIcon={<TooltipIcon titleKey={'general.select'} icon={<CheckBoxIcon />} />}
        //                 sx={{ color: 'secondary.light' }}
        //                 checked={params.row.selected}
        //                 onChange={() => {
        //                     params.row.selected = !params.row.selected;
        //                     onRowChanged(params, params.row);
        //                 }}
        //             />
        //         </Tooltip>
        //     )
        // },
    ].map(translate);


    const onDeleted = async (tag: TagRepresentation) => {
        setTags(tags.filter(t => t.id !== tag.id));
      }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-lg">
                <div className="flex justify-center space-x-4 pt-4">
                    <div style={{ height: '70vh', width: '100%' }}>
                        <Box>
                            <div style={{ height: props.height, width: '100%' }}>
                                <EditableGrid
                                    // emptyRow={emptyTagRepresentation}
                                    onDelete={onDeleted}
                                    disableDeleteModal={true}
                                    processRowUpdate={onUpserted}
                                    rows={tags}
                                    // topColumns={columns}
                                    columns={columns}
                                    modelType={"user"}
                                    toolbar={CustomToolbar}
                                    height='65vh'
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}