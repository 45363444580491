import { useReducer, FC } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  AppContext,
  ApplicationState,
  getDefaultState,
} from "./models/applicationState";
import appReducer from "./reducers";
import { Context } from "./components/context";
import Telemetry from "./components/telemetry";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./Login";
import React from "react";
import Layout from "./layout/mainLayout";

export const App: FC = () => {
  const defaultState: ApplicationState = getDefaultState();
  const [applicationState, dispatch] = useReducer(appReducer, defaultState);
  const initialContext: AppContext = {
    state: applicationState,
    dispatch: dispatch,
  };

  //   const appContext = useContext<AppContext>(Context);
  //   const navigate = useNavigate();
  //   const actions = useMemo(
  //     () => ({
  //       users: bindActionCreators(
  //         userActions,
  //         appContext.dispatch
  //       ) as unknown as UserActions,
  //     }),
  //     [appContext.dispatch]
  //   );

  //   const instance = getMsalInstance()
  //   const currentAccount = instance.getActiveAccount();

  //   useEffect(() => {
  //     console.info(`Load invoker <-> ${appContext.state.invoker?.email}`);
  //     if (!currentAccount) {
  //       actions.users.clearInvoker()
  //     } else {
  //         actions.users.getInvoker();
  //     }
  // }, [actions.users, navigate])

  return (
    <div>
      <AuthenticatedTemplate>
        <Context.Provider value={initialContext}>
          <BrowserRouter>
            <Telemetry>
              <React.Suspense fallback={<div>Loading...</div>}>
                <Layout />
              </React.Suspense>
            </Telemetry>
          </BrowserRouter>
        </Context.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};
