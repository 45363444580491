import * as itemActions from './itemActions';
import * as listActions from './listActions';
import * as eventActions from './eventActions';
import * as userActions from './userActions';
import * as projectFilterActions from './projectActions';

export enum ActionTypes {
    LOAD_LISTS = "LOAD_LISTS",
    LOAD_LIST = "LOAD_LIST",
    SELECT_LIST = "SELECT_LIST",
    SAVE_LIST = "SAVE_LIST",
    DELETE_LIST = "DELETE_LIST",
    LOAD_ITEMS = "LOAD_ITEMS",
    LOAD_ITEM = "LOAD_ITEM",
    SELECT_ITEM = "SELECT_ITEM",
    SAVE_ITEM = "SAVE_ITEM",
    DELETE_ITEM = "DELETE_ITEM",
    LOAD_CALENDAR_EVENTS = "LOAD_CALENDAR_EVENTS",
    LOAD_CALENDAR_EVENT = "LOAD_CALENDAR_EVENT",
    SELECT_CALENDAR_EVENT = "SELECT_CALENDAR_EVENT",
    SET_INVOKER_USER = "SET_INVOKER_USER",
    CLEAR_INVOKER_USER = "CLEAR_INVOKER_USER",
    SET_SELECTED_PROJECT_FILTERS = "SET_SELECTED_PROJECT_FILTERS",
}

export type Actions =
    itemActions.ListItemsAction |
    itemActions.SelectItemAction |
    itemActions.LoadItemAction |
    itemActions.SaveItemAction |
    itemActions.DeleteItemAction |
    listActions.ListListsAction |
    listActions.SelectListAction |
    listActions.LoadListAction |
    listActions.SaveListAction |
    listActions.DeleteListAction |
    eventActions.SelectEventAction |
    userActions.SetInvokerAction |
    userActions.ClearInvokerAction |
    projectFilterActions.SetSelectedProjectAction;