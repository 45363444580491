import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Avatar, Chip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
interface FreeMultipleSelectProps {
    onValueSelected: (string) => void
    options: string[]
    values?: string[]
    placeholder?: string
}

function stringToColor(string: string, saturation: number, lightness: number) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    // let color = '#';

    // for (i = 0; i < 3; i += 1) {
    //     const value = (hash >> (i * 8)) & 0xff;
    //     color += `00${value.toString(16)}`.slice(-2);
    // }
    /* eslint-enable no-bitwise */

    return 'hsl(' + hash + ', ' + saturation + '%, ' + lightness + '%)';
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name, 50, 85),
        },
        children: name.substring(0, 2)
    };
}

const finalTheme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                // Name of the slot
                inputRoot: {
                    // Some CSS for preventing row height errors
                    // paddingRight:'0px !important', 
                    // padding: '6px 0px 6px 0px !important',
                    maxHeight: '73px !important',
                    overflowY: 'auto'
                },
            },
        },
    },
});

export const FreeMultipleSelect: React.FC<FreeMultipleSelectProps> = (props: FreeMultipleSelectProps): React.ReactElement => {
    return (
        <ThemeProvider theme={finalTheme}>
        <Autocomplete
            // selectOnFocus
            disableClearable
            multiple
            filterSelectedOptions
            fullWidth
            freeSolo
            options={(props.options || [])}
            value={props.values || []}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip
                        variant="outlined"
                        avatar={<Avatar {...stringAvatar(option)} />}
                        title={option}
                        label={option.split('@')[0] || option}
                        size='small'
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(inputParams) =>
                <TextField
                    {...inputParams}
                    variant="standard"
                    placeholder={props.values?.length ? "" : (props.placeholder || "")}
                    InputProps={{
                        ...inputParams.InputProps,
                        disableUnderline: true
                    }}
                // onBlur={(_event) => {
                //     console.info(`ONBLUR TF ${inputParams.inputProps.value}`);
                //     if (inputParams.inputProps?.value) {
                //         props.onValueSelected([inputParams.inputProps.value]);
                //     }
                // }}
                />}
            onChange=
            {
                (event, value, _reason) => {
                    event?.stopPropagation();
                    props.onValueSelected(value);
                }
            }
            
        />
        </ThemeProvider>
    );
}