const settings: AppSettings = {  
    'clientId': '869098b4-1f7e-41bb-9653-875cece129a5',
    'tenantId': '7abbd1a7-be55-4ad9-9123-98170cd07d56', // 'common' if shared across tenants
    'graphUserScopes': [
      // 'Calendars.Read',
      // 'Calendars.Read.Shared'
    ]
  };
  
  export interface AppSettings {
    clientId: string;
    tenantId: string;
    graphUserScopes: string[];
  }
  
  export default settings;