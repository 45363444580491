
import axios, { AxiosInstance } from 'axios';
import { BrowserAuthError, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { getMsalInstance } from './authUtility';
import { parseSelectedProjectIds, parseSelectedTagIds } from '../util/filterHandler';

export const LAST_LOGIN_REDIRECT = 'lastLoginRedirect';

async function getSilentToken(): Promise<{ accessCode: string, idToken: string } | undefined> {
    try {
        const instance = getMsalInstance();
        const currentAccount = instance.getActiveAccount();
        const accessTokenRequest: SilentRequest = {
            scopes: loginRequest.scopes, // Scopes for which you want to acquire the token
            account: currentAccount ? currentAccount : undefined,
        };
        let result;
        try {
            result = await instance.acquireTokenSilent(accessTokenRequest);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                console.info('Silent token acquisition failed. Acquiring token using redirect');
                localStorage.setItem(LAST_LOGIN_REDIRECT, new Date().getTime().toString());
                result = await instance.acquireTokenRedirect(accessTokenRequest);
                console.info(`Token acquired using redirect: ${result.accessToken} ${result.idToken}`);
            } else {
                throw error;
            }
        }
        return { accessCode: result.accessToken, idToken: result.idToken };
    } catch (error) {
        console.error('Error while retrieving token:', error);
    }
}

export function setupAxiosInterceptor(onRejected?: (error: any) => any) {
    axios.interceptors.response.use((response) => response, onRejected);
}

function getAxiosFactory(baseUrl: string, baseRoute: string): () => Promise<AxiosInstance> {
    return async () => {
        const token = await getSilentToken();
        const axiosInstance = axios.create({
            baseURL: `${baseUrl}${baseRoute}`,
            headers: {
                "Authorization": `Bearer ${token.accessCode}`,
                "Identity": `${token.idToken}`,
                "projectids": parseSelectedProjectIds().join(','),
                "tagids": parseSelectedTagIds().join(','),
                "Content-Type": "application/json"
            }
        });
        axiosInstance.interceptors.response.use((response) => response, (error) => {
            const lastRedirectTime = localStorage.getItem(LAST_LOGIN_REDIRECT);
            if (error.response?.status === 401 && new Date(+lastRedirectTime).getTime() < new Date().getTime() - 1000 * 60) {
                localStorage.setItem(LAST_LOGIN_REDIRECT, new Date().getTime().toString());
                console.error('401 Error on Axios response:', error);
                const instance = getMsalInstance();
                const currentAccount = instance.getActiveAccount();
                instance.acquireTokenRedirect({
                    scopes: loginRequest.scopes,
                    account: currentAccount ? currentAccount : undefined,
                });
            }
        });
        return axiosInstance;
    }
}

export default getAxiosFactory;