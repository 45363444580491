import { Reducer } from "react";
import { ActionTypes, Actions } from "../actions/common";
import { Meeting } from "../models"

export const listsReducer: Reducer<Meeting[], Actions> = (state: Meeting[], action: Actions): Meeting[] => {
    switch (action.type) {
        case ActionTypes.LOAD_LISTS:
            state = [...action.payload];
            break;
        case ActionTypes.SAVE_LIST: {
            const existing = [...state || []];
            const index = existing.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                existing.splice(index, 1, action.payload);
                state = existing;
            } else {
                state = [...existing, action.payload];
            }
            if (action.payload.archived) {
                // If archived, remove from context (we would load those on demand separately)
                state = [...state.filter(list => list.id !== action.payload.id)];
            }
            break;
        }
        case ActionTypes.DELETE_LIST:
            state = [...state.filter(list => list.id !== action.payload)]
    }

    return state;
}