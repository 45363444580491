import ReactDOM from "react-dom";
import "./App.css";
import { App } from "./App";
import "./i18n/config.js";
import reportWebVitals from "./reportWebVitals";

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { initializeMsalInstance } from "./services/authUtility";
import settings from "./appSettings";
import config from "./config";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig(settings.tenantId, config.web.baseUrl));
// Account selection logic is app dependent. Adjust as needed for different use cases.

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    // const authority = msalInstance.getActiveAccount()?.tenantId || 'common';
    // console.info(`SETTING MSAL CONFIG AUTHORITY TO ${authority}`)
    // msalInstance.getConfiguration().auth.authority = `https://login.microsoftonline.com/${authority}`;
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      // Clear highlighted items
      window.sessionStorage.setItem("actorHighlightedItems", "[]");
      window.sessionStorage.setItem("creatorHighlightedItems", "[]");
    } else if (event.eventType === EventType.LOGOUT_SUCCESS && event.payload) {
      msalInstance.setActiveAccount(undefined);
    }
  });

  //set this msal instance in functional context (for token generation in services)
  initializeMsalInstance(msalInstance);

  ReactDOM.render(
    // <ErrorBoundary
    //     FallbackComponent={ErrorFallback}
    //     onError={(error: Error, info: ErrorInfo) => console.log(`Error occurred: ${JSON.stringify(error)} - ${JSON.stringify(info)}`)}
    //   >
      
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>,
    // </ErrorBoundary>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
