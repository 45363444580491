import { useContext, useEffect, useMemo, useState } from "react";
import React from "react";
import { AppContext } from "../../models/applicationState";
import { Box, Button, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel, GridTreeNodeWithRender } from "@mui/x-data-grid";
import { ApiUser, Meeting, MeetingStatus, UserRole } from "../../models";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import config from "../../config";
import { MeetingHeader } from "../../components/meetingHeader";
import { Context } from "../../components/context";
import { bindActionCreators } from "../../actions/actionCreators";
import { ListActions } from "../../actions/listActions";
import * as listActions from "../../actions/listActions";
import { useTranslation } from "react-i18next";
import { bulkCreateUsers } from "../../services/userService";
import { useNavigate, useParams } from "react-router-dom";
import { ItemActions } from "../../actions/itemActions";
import * as itemActions from "../../actions/itemActions";
import { addSystemCommentToItem } from "../../components/itemEditingPanel";
import { useMsal } from "@azure/msal-react";

export interface ParticipantDisplayInfo {
    id: string;
    email: string;
    notification: string;
}

const getAllMeetingEmails = (meeting: Meeting): string[] => {
    const allEmails = [];
    if (meeting?.status !== MeetingStatus.Published && meeting?.participants) {
        // We only add participants to the list before publishing
        for (const email of meeting.participants) {
            if (!allEmails.includes(email)) {
                allEmails.push(email);
            }
        }
    }
    if (meeting?.viewers) {
        for (const email of meeting.viewers) {
            if (!allEmails.includes(email)) {
                allEmails.push(email);
            }
        }
    }
    return allEmails;
}
const createTable = (meeting: Meeting): ParticipantDisplayInfo[] => {
    const allEmails = getAllMeetingEmails(meeting);
    return allEmails ? allEmails.map(email => ({
        id: email,
        email: email,
        notification: "to"
    })) : [];
};

export const ReviewPage = () => {
    // const { meetingId } = useParams();
    const appContext = useContext<AppContext>(Context);
    const [showMeetingPublished, setShowMeetingPublished] = useState(undefined);
    const { instance } = useMsal();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { meetingId } = useParams();
    const actions = useMemo(
        () => ({
            lists: bindActionCreators(
                listActions,
                appContext.dispatch
            ) as unknown as ListActions,
            items: bindActionCreators(
                itemActions,
                appContext.dispatch
            ) as unknown as ItemActions,
        }),
        [appContext.dispatch]
    );
    const [items, setItems] = useState<ParticipantDisplayInfo[]>(createTable(appContext.state.selectedList));
    const [newParticipant, setNewParticipant] = useState("");
    const processRowUpdate = (newRow: GridRowModel<ParticipantDisplayInfo>) => {
        setItems(items.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const [publishText, setPublishText] = useState('button.publish');

    useEffect(() => {
        if (meetingId && appContext.state.selectedList?.id !== meetingId) {
            actions.lists.load(meetingId);
        }
        setPublishText(appContext.state.selectedList?.status === MeetingStatus.Published ? 'button.published' : 'button.publish');
        setItems(createTable(appContext.state.selectedList));
    }, [actions.lists, appContext.state.selectedList, meetingId, navigate]);

    // Load items for selected list
    useEffect(() => {
        if (appContext.state.selectedList?.id && !appContext.state.selectedList.items) {
            const loadListItems = async (listId: string) => {
                await actions.items.list(listId);
            }
            loadListItems(appContext.state.selectedList.id)
        }
    }, [actions.items, appContext.state.selectedList?.id, appContext.state.selectedList?.items, navigate])

    const translate = (col: GridColDef): GridColDef => {
        return { ...col, headerName: t(col.headerName) };
    };

    const publishMeetingWithUsers = async (usersInput: ParticipantDisplayInfo[], newMeetingStatus: MeetingStatus) => {
        const meeting = appContext.state.selectedList;
        const viewers = usersInput.filter(item => item.notification === "to" || item.notification === "cc").map(item => item.email);
        meeting.viewers = viewers;
        meeting.status = newMeetingStatus;
        actions.lists.save(meeting);

        const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;

        if (appContext.state.selectedList?.items) {
            await Promise.all(appContext.state.selectedList.items.map(item => {
                addSystemCommentToItem(item, userFullName, 'Published');
                return actions.items.save(appContext.state.selectedList.id, item);
            }
            ));
        }

        const users: ApiUser[] = usersInput.map(u => ({ email: u.email, role: UserRole.Actor }));
        await bulkCreateUsers(users);
    }

    const publishAsync = async () => {
        await publishMeetingWithUsers(items, MeetingStatus.Published);
        const toUsers = items.filter(item => item.notification == "to");
        const ccUsers = items.filter(item => item.notification == "cc");
        if (toUsers.length > 0 || ccUsers.length > 0) {
            const toList = toUsers.map(item => item.email).join(',');
            const ccList = ccUsers.map(item => item.email).join(',');

            const subject = t('mail_template.meeting_minutes_subject', { meetingName: appContext.state.selectedList?.name });
            const bodyContent = t('mail_template.meeting_minutes_content', { baseUrl: config.web.baseUrl, meetingId: appContext.state.selectedList?.id, creator: appContext.state.selectedList.creator });
            const ccListContent = ccList ? `&cc=${ccList}` : '';
            const mailtoUrl = `mailto:${toList}?subject=${encodeURIComponent(subject)}${ccListContent}&body=${encodeURIComponent(bodyContent)}`;

            // Open the mail client
            window.location.href = mailtoUrl;
        }
        setShowMeetingPublished(appContext.state.selectedList?.id);
    }

    const publish = () => {
        publishAsync();
    }

    const columns: GridColDef[] = [
        { field: 'email', headerName: appContext.state.selectedList?.status === MeetingStatus.Published ? 'library.viewers' : 'review.email', width: 300, editable: false },
    ];

    if (appContext.state.selectedList?.status !== MeetingStatus.Published) {
        columns.push(
            {
                field: 'notification', headerName: 'review.notification', headerAlign: 'center', width: 170, editable: false,
                renderCell: (params) =>
                    <ToggleButtonGroup
                        value={params.row.notification}
                        exclusive
                        onChange={(
                            event: React.MouseEvent<HTMLElement>,
                            selection: string | null,
                        ) => handleToggle(event, selection, params)}
                        aria-label={t('review.notification_label')}
                    >
                        <ToggleButton size="small" value="to" aria-label="To" color='primary'>
                            <ForwardToInboxIcon />
                        </ToggleButton>
                        <ToggleButton size="small" value="cc" aria-label="CC" color='primary'>
                            <ClosedCaptionIcon />
                        </ToggleButton>
                        <ToggleButton size="small" value="x" aria-label="X" color='error'>
                            <CancelIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
            }
        );
    }
    const translatedColumns: GridColDef[] = columns.map(translate);

    // Handle participant changes
    useEffect(() => {
        setItems(createTable(appContext.state.selectedList));
    }, [appContext.state.selectedList]);

    const handleToggle = (
        event: React.MouseEvent<HTMLElement>,
        selected: string | null,
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
    ) => {
        event.preventDefault();
        params.row.notification = selected;
        params.api.updateRows([{ id: params.row.id, data: params.row }]);
        processRowUpdate(params.row);
    };

    const handleNewParticipantInput = (value: string) => {
        setNewParticipant(value);
    }

    const handleAddParticipant = (ev: React.KeyboardEvent) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            handleAddParticipantButtonClick();
        }
    }
    const handleAddParticipantButtonClick = () => {
        if (newParticipant.trim() !== "") {
            const newItems = [...items, { id: newParticipant.trim(), email: newParticipant.trim(), notification: "to" }];
            setItems(newItems);
            setNewParticipant("");
            if (appContext.state.selectedList?.status === MeetingStatus.Published) {
                publishMeetingWithUsers(newItems, MeetingStatus.Published);
            }
        }
    }
    return (
        <Box>
            {/* <CssBaseline /> */}
            <MeetingHeader meetingName={appContext.state.selectedList?.name}
                primaryBtnText={t(publishText)}
                disablePrimaryBtn={appContext.state.selectedList?.status === MeetingStatus.Published}
                onPrimaryBtnClick={publish}
            />

            <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid rows={items}
                    columns={translatedColumns}
                    disableRowSelectionOnClick={true}
                    rowSelection={false}
                    processRowUpdate={processRowUpdate}
                />
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <CssBaseline /> */}
                <TextField
                    value={newParticipant}
                    sx={{ width: '300px' }}
                    onChange={(ev) => handleNewParticipantInput(ev.target.value || "")}
                    onKeyDown={(ev) => handleAddParticipant(ev)}
                    placeholder={t('review.participant_placeholder')}
                ></TextField>
                <IconButton onClick={handleAddParticipantButtonClick}>
                    <PersonAddIcon />
                </IconButton>
            </Box>
            {showMeetingPublished && (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg w-96">
                            <p className="text-base leading-normal mb-4">{t('library.published_navigation')}</p>
                            <div className="flex justify-center space-x-4 pt-4">

                                <Button variant="outlined" onClick={() => setShowMeetingPublished(undefined)}>
                                    <Typography variant="button" display="block">
                                        {t('button.cancel')}
                                    </Typography>
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    navigate(`/library/${showMeetingPublished}`, { state: { blockPrevious: true } });
                                    setShowMeetingPublished(undefined);
                                }}>
                                    <Typography variant="button" display="block">
                                        {t('button.continue')}
                                    </Typography>
                                </Button>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </Box>
    );
};
