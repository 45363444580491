import Box from '@mui/material/Box';
import { MeetingItemStatus } from '../models';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

const ItemStatus = ({ status, lastModifiedBy }) => {
    const { t } = useTranslation();
    const getStatusColor = () => {
        let color = "none";
        if (status === MeetingItemStatus.NotStarted) {
            color = "#00508B";
        }
        if (status === MeetingItemStatus.InProgress) {
            color = "#F9F871"
        }
        if (status === MeetingItemStatus.Completed) {
            color = "#4E7D46";
        }
        if (status === MeetingItemStatus.Cancelled) {
            color = "#CBB6A5";
        }
        if (status === MeetingItemStatus.OnHold) {
            color = "#777586";
        }
        return color;
    }

    // const getBorder = () => {
    //     return statusKeys.find(entry => entry === status) ? '1px solid lightgray' : 'none';
    // }

    function getContrast() {
        const hexcolor = getStatusColor();
        const r = parseInt(hexcolor.substring(1, 3), 16);
        const g = parseInt(hexcolor.substring(3, 5), 16);
        const b = parseInt(hexcolor.substring(5, 7), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    return (
        <Tooltip title={lastModifiedBy ? t('item_status.last_updated_by', { updater: lastModifiedBy }) : ''}>
        <Box
                sx={{
                // "& .MuiDataGrid-cellContent": {
                border: 'none',
                borderRadius: '15px',
                backgroundColor: getStatusColor(),
                color: getContrast(),
                width: 'auto',
                height: '32px',
                lineHeight: '32px',
                margin: '10px 0',
                textAlign: 'center',
                overflow: 'hidden'
                // },
            }}>
            {t(`item_status.${status}`)}
        </Box>
        </Tooltip>
    );
};

export default ItemStatus;