import { Reducer } from "react";
import { ActionTypes, Actions } from "../actions/common";
import { ProjectFilters } from "../models/project";

export const selectedProjectFiltersReducer: Reducer<ProjectFilters, Actions> = (state: ProjectFilters, action: Actions): ProjectFilters => {
    switch (action.type) {
        case ActionTypes.SET_SELECTED_PROJECT_FILTERS:
            state = action.payload ? { ...action.payload } : undefined;
            break;
    }
    return state;
}