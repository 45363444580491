export enum MeetingItemStatus {
    NotStarted = "notstarted",
    InProgress = "inprogress",
    Completed = "completed",
    Cancelled = "cancelled",
    OnHold = "onhold"
}


export interface MeetingItem {
    id?: string
    meetingId: string
    content: string
    status: MeetingItemStatus
    index?: number
    identifier?: string
    types?: string[]
    description?: string
    creator? : string
    isSnapshot?: boolean
    snapshotFor?: string
    actor?: string
    viewers? :string[]
    category? : string
    dueDate?: Date
    attachments?: number
    completedDate?: Date
    createdDate?: Date
    updatedDate?: Date
    project?: string
    tags?: string[],
    statusLastUpdatedBy?: string
    comments?: string
    archived?: boolean
}

export enum MeetingItemType {
    Task = "task",
    Info = "info",
    Decision = "decision"
}
