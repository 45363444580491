import {
  useEffect,
  useState,
} from "react";
import * as projectActions from "../../actions/projectActions";
import { withApplicationInsights } from "../../components/telemetry";
import { useNavigate } from "react-router-dom";
import CrudGrid from "../../components/crudGrid";
import { Box, Checkbox, CssBaseline, Tooltip } from "@mui/material";
import { emptyProjectRepresentation, nameColumn, tagsColumn, templateColumn, usersColumn } from "../../models/projectRepresentation";
import { useTranslation } from "react-i18next";
import { Project } from "../../models/project";
import { Tag, TagType } from "../../models/tag";
import { FreeCheckboxMultipleSelect } from "../../components/freeCheckboxMultipleSelect";
import { TagsSelectionPanel } from "../../components/tagSelectionPanel";
import * as userActions from "../../actions/userActions";
import { GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { TooltipIcon } from "../../components/tooltipIcon";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React from "react";
import { AppContext } from "../../models/applicationState";
import { Context } from "../../components/context";
import { UserActions } from "../../actions/userActions";
import { bindActionCreators } from "../../actions/actionCreators";

const ProjectsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const appContext = React.useContext<AppContext>(Context);
  const actions = React.useMemo(() => ({
    users: bindActionCreators(userActions, appContext.dispatch) as unknown as UserActions
}), [appContext.dispatch]);

  const [showTagsPanelForProject, setShowTagsPanelForProject] = useState(undefined);

  const [users, setUsers] = useState([]);

  const loadUsers = async () => {
    const users = await userActions.list();
    await setUsers(users);
  }

  useEffect(() => {
    loadUsers();
  }, [navigate]);

  const [projects, setProjects] = useState([]);
  const [templateTags, setTemplateTags] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const getTemplateTags = (projects: Project[]) => {
    const templateProjectTags = projects.filter(p => p.isTemplate).map(p => p.tags).flat();
    const tags =  templateProjectTags.filter(t =>  t.name).map(t => ({ name: t.name, type: t.type }));
    return tags.filter((tag, index, self) =>
      index === self.findIndex((t) => (
        t.name === tag.name && t.type === tag.type
      ))
    );
  }

  const sortProjects = (projects: Project[]) => {
    return [...(projects || [])]?.sort((a, b) => {
      if (a.createdDate < b.createdDate) return 1;
      if (a.createdDate > b.createdDate) return -1;
      return 0;
    });
  }

  const loadProjects = async () => {
    const projects = await projectActions.list();
    setTemplateTags(getTemplateTags(projects));
    setProjects(sortProjects(projects));
  }

  useEffect(() => {
    loadProjects();
  }, [navigate, refreshData]);

  const onUpserted = async (newProject: Project, _oldProject?: Project) => {
    await projectActions.save(newProject);
    setRefreshData(!refreshData);
  }

  const onDeleted = async (project: Project) => {
    if (project.id === emptyProjectRepresentation.id) {
      console.info(`Returning on project upserted, this should not happen ${project.id}`);
      return;
    }
    await projectActions.remove(project.id);
    setRefreshData(!refreshData);
  }

  const formatValues = (values, key) => {
    return values?.map(v => {
      if (v[key] !== undefined) {
        return v[key];
      }
      return v;
    });
  };

  const formatInputs = (values: (Tag | string)[]): Tag[] => {
    return values.map(v => {
      if (typeof v === 'string') {
        return { name: v, type: TagType.Workstream };
      }
      return v;
    });
  };

  const getFreeMultipleSelectComponentForUsers = (saveUpsert: boolean) => {
    return {
      ...usersColumn,
      editable: false,
      cellClassName: 'scroll-overflow',
      renderCell: (params) => (
        <FreeCheckboxMultipleSelect
          onValueSelected={(value) => {
            params.row.users = value;
            if (saveUpsert) {
              onUpserted(params.row);
            }
            params.api.updateRows([{ id: params.row.id, data: params.row }]);
          }}
          options={users}
          values={params.row.users}
          placeholder={t('project.addUsers')}
          formatValues={(values) => formatValues(values, 'email')}
          disableFreeSolo={true}
          isOptionEqualToValue={(option, value) => option.email === value.email}
        />
      )
    };
  }

  const getTagOptions = (project: Project) => {
    const disconnectedTags = project.disconnectedTags || [];
    const templateTagsNotInProject = templateTags.filter(t => !project.tags.find(tg => tg.name === t.name) && !project.disconnectedTags.find(dt => dt.name === t.name));
    return disconnectedTags.concat(templateTagsNotInProject);
  }

  const getFreeMultipleSelectComponentForTags = (saveUpsert: boolean) => {
    return {
      ...tagsColumn,
      editable: false,
      cellClassName: 'scroll-overflow',
      renderCell: (params) => (
        <FreeCheckboxMultipleSelect
          onValueSelected={(value) => {
            params.row.tags = value;
            if (saveUpsert) {
              onUpserted(params.row);
            }
            params.api.updateRows([{ id: params.row.id, data: params.row }]);
          }}
          options={getTagOptions(params.row)}
          values={params.row.tags}
          placeholder={t('project.addTags')}
          formatValues={(values) => formatValues(values, 'name')}
          formatInputs={formatInputs}
          isOptionEqualToValue={(option, value) => option.name === value.name}
        />
      )
    };
  }

  const showTagsManagement = (id: GridRowId) => () => {
    setShowTagsPanelForProject(id);
  };

  const tagsAction = (id: GridRowId, row: Project) => {
    return ([
      <GridActionsCellItem
        icon={<TooltipIcon titleKey={'project.manageTags'} icon={<ManageAccountsIcon />} />}
        label="Open"
        onClick={showTagsManagement(id)}
        sx={{
          color: 'primary.main',
        }}
      />]);
  }

  const getColumns = (upsertOnEdit: boolean): GridColDef[] => {
    return [
      nameColumn,
      getFreeMultipleSelectComponentForUsers(upsertOnEdit),
      getFreeMultipleSelectComponentForTags(upsertOnEdit),
      {
        ...templateColumn as GridColDef,
        renderCell: (params) => (
          <Tooltip title={t('project.isTemplate')}>
              <Checkbox
                  icon={<TooltipIcon titleKey={'general.select'} icon={<CheckBoxOutlineBlankIcon />} />}
                  checkedIcon={<TooltipIcon titleKey={'general.select'} icon={<CheckBoxIcon />} />}
                  sx={{ color: 'secondary.light' }}
                  checked={params.row.isTemplate}
                  onChange={() => {
                      params.row.isTemplate = !params.row.isTemplate;
                      if (upsertOnEdit) {
                        onUpserted(params.row);
                      }
                      params.api.updateRows([{ ...params.row }]);
                  }}
              />
          </Tooltip>
      )
    }];
  }
  return (
    <Box>
      <CssBaseline />
      {showTagsPanelForProject && (
        <TagsSelectionPanel
          height='75vh'
          project={projects.find(p => p.id === showTagsPanelForProject)}
          onClose={(project) => {
            onUpserted(project);
            setShowTagsPanelForProject(undefined);
          }}
          onCancel={() => setShowTagsPanelForProject(undefined)}
        />
      )}
      <CrudGrid
        processRowUpdate={onUpserted}
        onDelete={onDeleted}
        rows={projects}
        topColumns={getColumns(false)}
        columns={getColumns(true)}
        extraActions={tagsAction}
        height='75vh'
        emptyRow={emptyProjectRepresentation}
        modelType={"project"}
      />
    </Box>
  );
};
export default withApplicationInsights(ProjectsPage, "ProjectsPage");
