import { BlobServiceClient } from "@azure/storage-blob";
import { randomId } from "@mui/x-data-grid-generator";
import getAxiosFactory from "./customAxios";
import config from "../config";

const containerName = `uploads`;
const storageAccountName = 'zje2yzm5mzdky';

export interface Attachment {
    id: string
    url: string
    name: string
    displayName: string
    creatorEmail?: string
    external?: boolean
}
const getContainerClient = (token: string) => {
    const uploadUrl = `https://${storageAccountName}.blob.core.windows.net/?${token}`;
    const blobService = new BlobServiceClient(uploadUrl);
    return blobService.getContainerClient(containerName);
}

const getContainerSASToken = async () => {
    const factory = await getAxiosFactory(config.api.baseUrl, '/blobs/container')();
    const response = await factory.request({
        method: 'POST'
    });
    return response.data.token;
}

const getBlobSASToken = async (meetingId: string, fileName: string, itemId?: string) => {
    let url;
    if (itemId) {
        url = `/blobs/blob/${meetingId}/${itemId}/${fileName}`;
    } else {
        url = `/blobs/blob/${meetingId}/${fileName}`;
    }
    console.info(`URL getBlobSASToken - ${url}`);
    const factory = await getAxiosFactory(config.api.baseUrl, url)();
    const response = await factory.request({
        method: 'POST'
    });
    return response.data.token;
}

export const createBlobInContainer = async (prefix: string, file: File, meetingId: string, itemId?: string): Promise<Attachment> => {
    try {
        const blobToken = await getBlobSASToken(meetingId, file.name, itemId);
        const blobName = prefix + file.name;
        const blobClient = getContainerClient(blobToken).getBlockBlobClient(blobName);
        // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: file.type } };
        await blobClient.uploadData(file, options);
        return {
            id: randomId(),
            url: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobName}`,
            name: blobName,
            displayName: file.name
        };
    } catch (error) {
        console.error(`Error creating blob "${prefix + file.name}":`, error);
        throw error;
    }
};

export const deleteBlobFromContainer = async (meetingId: string, fullBlobName: string, itemId?: string): Promise<void> => {
    try {
        const blobToken = await getBlobSASToken(meetingId, fullBlobName.substring(fullBlobName.lastIndexOf('/') + 1), itemId);
        // Get the blob client for the specified blob
        const blobClient = getContainerClient(blobToken).getBlockBlobClient(fullBlobName);
        await blobClient.delete();
    } catch (error) {
        console.error(`Error deleting blob "${fullBlobName}":`, error);
    }
};

const decorateBlobs = async (attachments: Attachment[]): Promise<Attachment[]> => {
    const containerToken = await getContainerSASToken();
    return attachments.map(a => (a.external ? a : { ...a, url: a.url + `?${containerToken}` }));
};

export const getAttachments = async (meetingId: string, itemId?: string): Promise<Attachment[]> => {
    const url = `/attachments/${meetingId}/item/${itemId}`;
    const factory = await getAxiosFactory(config.api.baseUrl, url)();
    const response = await factory.request({
        method: 'GET'
    });
    const attachments = response.data;
    return decorateBlobs(attachments);
}

export const createAttachment = async (attachment: Attachment, meetingId: string, itemId?: string): Promise<Attachment> => {
    const url = `/attachments/${meetingId}/item/${itemId}`;
    const factory = await getAxiosFactory(config.api.baseUrl, url)();
    const response = await factory.request({
        method: 'POST',
        data: attachment
    });

    return response.data;
}

export const deleteAttachment = async (attachment: Attachment, meetingId: string, itemId?: string): Promise<void> => {
    const url = `/attachments/${meetingId}/item/${itemId}/${attachment.id}`;
    const factory = await getAxiosFactory(config.api.baseUrl, url)();
    await factory.request({
        method: 'DELETE'
    });
}