import { GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { withApplicationInsights } from "../../components/telemetry";
import { MeetingItem, MeetingItemStatus } from "../../models";
import { useContext, useEffect, useMemo, useState } from 'react';
import * as dashboardActions from '../../actions/actorDashboardActions';
import { bindActionCreators } from '../../actions/actionCreators';
import { AppContext } from '../../models/applicationState';
import { Context } from '../../components/context';
import { ItemActions } from '../../actions/itemActions';
import * as itemActions from '../../actions/itemActions';
import { Badge, Box, Checkbox, CssBaseline, Tooltip } from '@mui/material';
import EditableGrid from '../../components/editableGrid';
import { MeetingItemRepresentation, categoryColumn, contentColumn, convertToRepresentation, createdDateColumn, creatorColumn, decisionColumn, dueDateColumn, getMeetingItemFromRepresentation, hasRepresentationChanged, identifierColumn, informationColumn, meetingNameColumn, statusColumn, statusTranslatedLabelOptions, taskColumn, typesColumn } from '../../models/meetingItemRepresentation';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import TaskIcon from '@mui/icons-material/Task';
import SignpostIcon from '@mui/icons-material/Signpost';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { AttachmentsPane } from '../../components/attachmentsPane';
import ItemStatus from '../../components/itemStatus';
import { useTranslation } from 'react-i18next';
import { TooltipIconWithBadge } from '../../components/tooltipIconWithBadge';
import CustomToolbar from '../../components/customToolbar';
import { ListActions } from '../../actions/listActions';
import * as listActions from "../../actions/listActions";
import SearchIcon from '@mui/icons-material/Search';
import { ItemEditingPanel, hasUserComments } from '../../components/itemEditingPanel';
import { TooltipIcon } from '../../components/tooltipIcon';
import EventNoteIcon from '@mui/icons-material/EventNote';

const ActorDashboard = () => {
    const ACTOR_SORT_FIELD = "actor.sortField";
    const ACTOR_SORT_DIRECTION = "actor.sortDirection";
    const HIGHLIGHTED_ITEMS_KEY = "actorHighlightedItems"
    const navigate = useNavigate();
    const appContext = useContext<AppContext>(Context)
    const [items, setItems] = useState<MeetingItemRepresentation[]>([]);
    const [gridItems, setGridItems] = useState<MeetingItemRepresentation[]>([]);
    const [showCompleted, setShowCompleted] = useState(true);
    const [highlightedItemIds, setHghlightedItemIds] = useState<string[]>(JSON.parse(window.sessionStorage.getItem(HIGHLIGHTED_ITEMS_KEY)));
    const [showAttachmentsForMeeting, setShowAttachmentsForMeeting] = useState(undefined);
    const [showAttachmentsForItem, setShowAttachmentsForItem] = useState(undefined);
    const { t } = useTranslation();
    const [selectedProjectFilters, setSelectedProjectFilters] = useState(appContext.state.selectedProjectFilters);
    const [showEditingPanelForItem, setShowEditingPanelForItem] = useState(undefined);

    const actions = useMemo(() => ({
        items: bindActionCreators(itemActions, appContext.dispatch) as unknown as ItemActions,
        lists: bindActionCreators(listActions, appContext.dispatch) as unknown as ListActions,
    }), [appContext.dispatch]);

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
            fetchData();
        }
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate]);

    useEffect(() => {
        const existingStorage = window.sessionStorage.getItem(HIGHLIGHTED_ITEMS_KEY);
        if (existingStorage) {
            const existingHighlightedItems = JSON.parse(existingStorage) as string[];
            if (existingHighlightedItems?.length !== highlightedItemIds?.length) {
                const stringToStore = JSON.stringify(highlightedItemIds);
                window.sessionStorage.setItem(HIGHLIGHTED_ITEMS_KEY, stringToStore);
            }
        } else {
            window.sessionStorage.setItem(HIGHLIGHTED_ITEMS_KEY, JSON.stringify(highlightedItemIds));
        }
    }, [highlightedItemIds]);

    useEffect(() => {
        if (showCompleted) {
            setGridItems(items);
        } else {
            setGridItems(items.filter(i => i.status !== MeetingItemStatus.Completed));
        }
    }, [showCompleted, items]);

    async function fetchData() {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.list();
            setItems(convertToRepresentation(response).filter(i => !i.isSnapshot));
        }
    }

    const onArchived = (archived: boolean) => {
        onArchivedAsync(archived);
    }

    const onArchivedAsync = async (archived: boolean) => {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.list({ archived });
            setItems(convertToRepresentation(response).filter(i => !i.isSnapshot));
        }
    };

    const onCompleted = (completed: boolean) => {
        onCompletedAsync(completed);
    }

    const onCompletedAsync = async (completed: boolean) => {
        setShowCompleted(completed);
    };

    async function executeCallbackIfDiffsExist(callback?: () => Promise<void>) {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.listDiffs();
            if (response.length) {
                const highlightsToStore = highlightedItemIds?.length ? [...highlightedItemIds] : [];
                const newHighlightedIds = response.map(i => i.id);
                if (newHighlightedIds) {
                    for (const highlightedItemId of newHighlightedIds) {
                        if (!highlightsToStore.includes(highlightedItemId)) {
                            highlightsToStore.push(highlightedItemId);
                        }
                    }
                    setHghlightedItemIds(highlightsToStore);
                }
                if (callback) {
                    callback();
                }
            }
        }
    }

    useEffect(() => {
        executeCallbackIfDiffsExist();
        // Always fetch data on the initial load
        fetchData();
        const interval = setInterval(() => {
            executeCallbackIfDiffsExist(fetchData);
        }, 10000);
        return () => clearInterval(interval);
    }, [navigate, showAttachmentsForMeeting, showAttachmentsForItem]);

    const onItemUpserted = async (item: MeetingItem) => {
        return await actions.items.save(item.meetingId, item);
    }

    const processRowUpdate = (newRow: GridRowModel<MeetingItemRepresentation>) => {
        if (!hasRepresentationChanged(newRow)) {
            return newRow;
        }
        newRow.data = getMeetingItemFromRepresentation(newRow);
        setItems(items.map((row) => (row.id === newRow.id ? newRow : row)));
        onItemUpserted(newRow.data);
        return newRow;
    };

    const openItemEditingPanel = (item: MeetingItemRepresentation) => () => {
        setShowEditingPanelForItem({ item });
    };

    const onItemUpdated = async (itemRepresentation: MeetingItemRepresentation) => {
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        onItemUpserted(item);
    }

    const openMeetingAttachments = (meetingId: string) => () => {
        setShowAttachmentsForMeeting(meetingId);
    };

    const openItemAttachments = (id: GridRowId, meetingId: string) => () => {
        setShowAttachmentsForItem({ id, meetingId });
    };

    const onMeetingSelected = async (meetingId: string) => {
        actions.lists.load(meetingId);
    }

    const onNavigate = (meetingId: string) => {
        if (meetingId) {
            onMeetingSelected(meetingId);
            navigate(`/library/${meetingId}/current`);
        }
    };

    const handleClickNavigation = (item: MeetingItemRepresentation) => () => {
        onNavigate(item?.meetingId);
    };

    const meetingAttachmentsColumn: GridColDef<any> = {
        field: 'meetingAttachments',
        type: 'actions',
        headerName: 'dashboard.meetingAttachments',
        flex: 0.3,
        cellClassName: 'actions',
        getActions: ({ row }) => {
            return [
                <GridActionsCellItem
                    icon={
                        <TooltipIconWithBadge
                            badgeContent={row.meetingAttachments}
                            titleKey='dashboard.meetingAttachments'
                            icon={< FileCopyIcon fontSize="small" />}
                        />
                    }
                    label={t('dashboard.meetingAttachments_label')}
                    onClick={openMeetingAttachments(row.meetingId)}
                    sx={{
                        color: 'primary.main',
                    }}
                />,
            ];
        },
    }

    const itemAttachmentsColumn: GridColDef<any> = {
        field: 'itemAttachments',
        type: 'actions',
        headerName: 'dashboard.itemAttachments',
        flex: 0.3,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
            return [
                <GridActionsCellItem
                    icon={
                        <TooltipIconWithBadge
                            badgeContent={row.attachments}
                            titleKey='dashboard.itemAttachments'
                            icon={< FilePresentIcon fontSize="small" />}
                        />
                    }
                    label={t('dashboard.itemAttachments_label')}
                    onClick={openItemAttachments(id, row.meetingId)}
                    sx={{
                        color: 'primary.main',
                    }}
                />,
            ];
        },
    }

    const extraActions = (id: GridRowId, row: MeetingItemRepresentation) => {
        return ([
            <GridActionsCellItem
                        icon={<TooltipIconWithBadge titleKey={'general.open'} icon={<SearchIcon fontSize="small" />} badgeContent={hasUserComments(row.data) ? "i" : undefined} />}
                        label="Edit"
                        onClick={openItemEditingPanel(row)}
                        sx={{
                            color: 'primary.main',
                        }}
                    />,
                    <GridActionsCellItem
                        icon={
                            <TooltipIconWithBadge
                                badgeContent={row.meetingAttachments}
                                titleKey='dashboard.meetingAttachments'
                                icon={< FileCopyIcon fontSize="small" />}
                            />
                        }
                        label={t('dashboard.meetingAttachments_label')}
                        onClick={openMeetingAttachments(row.meetingId)}
                        sx={{
                            color: 'primary.main',
                        }}
                    />,
                    <GridActionsCellItem
                        icon={
                            <TooltipIconWithBadge
                                badgeContent={row.attachments}
                                titleKey='dashboard.itemAttachments'
                                icon={< FilePresentIcon fontSize="small" />}
                            />
                        }
                        label={t('dashboard.itemAttachments_label')}
                        onClick={openItemAttachments(row.id, row.meetingId)}
                        sx={{
                            color: 'primary.main',
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<TooltipIcon titleKey={'meeting.open'} icon={<EventNoteIcon />} />}
                        label={t('meeting.open')}
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleClickNavigation(row)}
                    />
        ]);
    }

    const columns: GridColDef[] = [
        {
            ...identifierColumn,
            renderCell: (params) => (
                <Badge color="primary" badgeContent=" " variant="dot"
                    sx={{ display: 'inline' }}
                    invisible={!highlightedItemIds?.includes(params.row.id)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <span>{params.row.identifier}</span>
                </Badge>
            )
        },
        createdDateColumn,
        { ...meetingNameColumn, width: 150},
        categoryColumn,
        { ...contentColumn, flex: 0.6},
        {
            ...typesColumn as GridColDef,
            renderCell: (params) => (
                <Box>
                    {params.row.task
                        && (
                            <Tooltip title={t('item.taskType')}>
                                <span>
                                    <Checkbox
                                        disabled
                                        checkedIcon={<TaskIcon />}
                                        checked={true} />
                                </span>
                            </Tooltip>
                        )}
                    {params.row.decision && (
                        <Tooltip title={t('item.decisionType')}>
                            <span>
                                <Checkbox
                                    disabled
                                    checkedIcon={<SignpostIcon />}
                                    checked={true} />
                            </span>
                        </Tooltip>
                    )}
                    {params.row.info
                        && (
                            <Tooltip title={t('item.informationType')}>
                                <span>
                                    <Checkbox
                                        disabled
                                        // icon={<InfoOutlinedIcon />}
                                        checkedIcon={<InfoIcon />}
                                        checked={true} />
                                </span>
                            </Tooltip>
                        )}
                </Box>
            ),
        },
        dueDateColumn,
        {
            ...statusColumn,
            type: 'singleSelect',
            valueFormatter: (value: string) => t(`item_status.${value}`),
            valueOptions: statusTranslatedLabelOptions(t),
            renderCell: (params) => (
                <ItemStatus status={params.row.status} lastModifiedBy={params.row.statusLastUpdatedBy}></ItemStatus>
            ),
        },
        {...creatorColumn, width: 150},
        // meetingAttachmentsColumn,
        // itemAttachmentsColumn,
    ];

    async function updateSortModel(newSortModel: GridSortModel) {
        localStorage.setItem(ACTOR_SORT_FIELD, newSortModel[0]?.field);
        localStorage.setItem(ACTOR_SORT_DIRECTION, newSortModel[0]?.sort);
    }

    return (
        <Box>
            <CssBaseline />
            {showAttachmentsForMeeting
                && (
                    <AttachmentsPane
                        allowModifications={false}
                        height='70vh'
                        uploadPrefix={`meetings/${showAttachmentsForMeeting}/`}
                        meetingId={showAttachmentsForMeeting}
                        onClose={() => setShowAttachmentsForMeeting(undefined)}
                        onAttachmentsCountChanged={(count, meetingId, _itemId) => setItems(items.map(item => item.meetingId === meetingId ? { ...item, meetingAttachments: count } : item))}
                    />
                )}
            {showEditingPanelForItem
                && (
                    <ItemEditingPanel meetingItem={showEditingPanelForItem.item} open={showEditingPanelForItem} onClose={() => setShowEditingPanelForItem(undefined)} onUpdate={onItemUpdated}
                    />
                )}
            {showAttachmentsForItem
                && (
                    <AttachmentsPane
                        allowModifications={true}
                        height='70vh'
                        uploadPrefix={`items/meetings/${showAttachmentsForItem.meetingId}/items/${showAttachmentsForItem.id}/`}
                        meetingId={showAttachmentsForItem.meetingId}
                        itemId={showAttachmentsForItem.id}
                        onClose={() => setShowAttachmentsForItem(undefined)}
                        onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}
            <EditableGrid
                height='90vh'
                processRowUpdate={processRowUpdate}
                rows={gridItems}
                columns={columns}
                sortField={localStorage.getItem(ACTOR_SORT_FIELD)}
                sortDirection={localStorage.getItem(ACTOR_SORT_DIRECTION) as GridSortDirection}
                updateSortModel={updateSortModel}
                toolbar={CustomToolbar}
                extraActions={extraActions}
                toolbarProps={
                    {
                        toolbar: {
                            showSettings: false,
                            onArchived: onArchived,
                            onCompleted: onCompleted,
                        }
                    }
                }
            />

        </Box >
    );
};

export default withApplicationInsights(ActorDashboard, "MyDashboard");
