import { GridColDef } from "@mui/x-data-grid";
import { UserRole } from "./user";
import dateFormatter from "../util/dateFormatter";

export const emptyUserRepresentation = {
    id: "2222",
    email: "",
    role: UserRole.Creator
}

export const userRoleLabels = {
    "admin": "Admin",
    "creator": "Creator",
    "actor": "Actor"
}

export const userRoleValueOptions = [...Object.entries(userRoleLabels).map(entry => {
    return {
        value: entry[0],
        label: entry[1]
    };
})];

export const emailColumn: GridColDef = { field: 'email', headerName: 'Email', editable: true, display: 'flex', width: 300 };
export const roleColumn: GridColDef = {
    field: 'role', headerName: 'Role', display: 'flex', width: 120,
    editable: true,
    type: 'singleSelect',
    valueFormatter: value => userRoleLabels[value],
    valueOptions: userRoleValueOptions
};
export const firstNameColumn: GridColDef = { field: 'firstName', headerName: 'First Name', editable: true, display: 'flex', width: 150 };
export const lastNameColumn: GridColDef = { field: 'lastName', headerName: 'Last Name', editable: true, display: 'flex', width: 150 };
export const organizationColumn: GridColDef = { field: 'organization', headerName: 'Organization', editable: true, display: 'flex', width: 150 };
export const commentsColumn: GridColDef = { field: 'comments', headerName: 'Comments', editable: true, display: 'flex', width: 200 };
export const createdByColumn: GridColDef = { field: 'createdBy', headerName: 'Created By', editable: false, display: 'flex', width: 150 };
export const createdAtColumn: GridColDef = {
    field: 'createdDate',
    headerName: 'Created On',
    editable: false,
    display: 'flex',
    width: 110,
    type: 'date',
    valueFormatter: value =>
        value ? dateFormatter.format(new Date(value)) : "No Date"
};