import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Meeting, MeetingStatus } from "../../models";
import * as listActions from "../../actions/listActions";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { ListActions } from "../../actions/listActions";
import { bindActionCreators } from "../../actions/actionCreators";
import { withApplicationInsights } from "../../components/telemetry";
import { addIcon, dateColumn, defaultTagsColumn, emptyMeetingRepresentation, identifierColumn, inviteesColumn, meetingStatusLabels, nameColumn, projectColumn, statusColumn } from "../../models/meetingRepresentation";
import CrudGrid from "../../components/crudGrid";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowId, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { AttachmentsPane } from "../../components/attachmentsPane";
import { FreeMultipleSelect } from "../../components/freeMultipleSelect";
import { EventActions } from "../../actions/eventActions";
import * as eventActions from '../../actions/eventActions';
import * as userActions from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SearchIcon from '@mui/icons-material/Search';
import { useMsal } from "@azure/msal-react";
import { TooltipIcon } from "../../components/tooltipIcon";
import { TooltipIconWithBadge } from "../../components/tooltipIconWithBadge";
import { FreeCheckboxMultipleSelect } from "../../components/freeCheckboxMultipleSelect";
import { Tag } from "../../models/tag";
import { Project } from "../../models/project";
import { FreeSingleSelect } from "../../components/freeSingleSelect";
import { UserActions } from "../../actions/userActions";

const MeetingsPage = () => {
  const MEETING = "meeting";
  const MEETING_SORT_FIELD = "meeting.sortField";
  const MEETING_SORT_DIRECTION = "meeting.sortDirection";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appContext = useContext<AppContext>(Context);
  const [showAttachmentsForMeeting, setShowAttachmentsForMeeting] = useState(undefined);
  const [projects, setProjects] = useState(appContext.state.invoker?.projects || []);
  const { instance } = useMsal();
  const [topRowProject, setTopRowProject] = useState(undefined);
  const [emptyMeeting, setEmptyMeeting] = useState(emptyMeetingRepresentation);
  const [placeholderRow, setPlaceholderRow] = useState(appContext.state.selectedEvent ? {
    ...emptyMeetingRepresentation,
    name: appContext.state.selectedEvent.name,
    date: appContext.state.selectedEvent.startDate,
    participants: appContext.state.selectedEvent.invitees,
  } : undefined);

  const actions = useMemo(
    () => ({
      lists: bindActionCreators(
        listActions,
        appContext.dispatch
      ) as unknown as ListActions,
      events: bindActionCreators(eventActions, appContext.dispatch) as unknown as EventActions,
      users: bindActionCreators(userActions, appContext.dispatch) as unknown as UserActions
    }),
    [appContext.dispatch]
  );

  useEffect(() => {
    actions.users.getInvoker();
  }, [navigate]);

  useEffect(() => {
    if (appContext.state.invoker?.projects?.length) {
      setProjects(appContext.state.invoker?.projects);
    }
  }, [navigate, appContext.state.invoker?.projects]);

  const filterMeetings = (meetings: Meeting[]) => {
    return meetings?.filter(m => m.status !== MeetingStatus.Published) || [];
  }

  const sortMeetings = (meetings: Meeting[]) => {
    return [...(meetings || [])]?.sort((a, b) => {
      if (a.createdDate < b.createdDate) return 1;
      if (a.createdDate > b.createdDate) return -1;
      return 0;
    });
  }

  const transformMeetings = (meetings: Meeting[]) => {
    return sortMeetings(filterMeetings(meetings));
  }

  const [meetings, setMeetings] = useState(transformMeetings(appContext.state.lists));

  useEffect(() => {
    setMeetings(transformMeetings(appContext.state.lists));
  }, [appContext.state.lists, navigate]);

  useEffect(() => {
    if (appContext.state.selectedEvent) {
      setPlaceholderRow({
        ...emptyMeetingRepresentation,
        name: appContext.state.selectedEvent.name,
        date: appContext.state.selectedEvent.startDate,
        participants: appContext.state.selectedEvent.invitees,
      });
    }
    actions.events.select(undefined);
  }, [appContext.state.selectedEvent, navigate]);

  useEffect(() => {
    if (emptyMeeting && instance.getActiveAccount()?.username) {
      const participants = emptyMeeting.participants || [];
      if (!participants.includes(instance.getActiveAccount()?.username)) {
        participants.push(instance.getActiveAccount()?.username);
        setEmptyMeeting({ ...emptyMeeting, participants });
      }
    }
  }, [instance.getActiveAccount()]);

  const onMeetingSelected = async (meeting: Meeting) => {
    actions.lists.select(meeting);
  }

  const onNavigate = (meeting: Meeting) => {
    if (meeting) {
      onMeetingSelected(meeting);
      navigate(`/meetings/${meeting.id}`);
    }
  };

  const onMeetingUpserted = async (newMeeting: Meeting, oldMeeting?: Meeting) => {
    if (newMeeting.id === emptyMeetingRepresentation.id) {
      return;
    }
    // if (JSON.stringify(newMeeting) !== JSON.stringify(oldMeeting)) {
    await actions.lists.save(newMeeting);
    // } else {
    //   console.info(`Ignored meeting update ${newMeeting.identifier} as it had no changes`);
    // }
  }

  const onItemDeleted = async (meeting: Meeting) => {
    if (meeting.id === emptyMeetingRepresentation.id) {
      console.info(`Returning on user upserted, this should not happen ${meeting.id}`);
      return;
    }
    if (meeting) {
      actions.lists.remove(meeting.id);
      navigate(`/meetings`);
    }
  }

  const openAttachments = (id: GridRowId) => () => {
    setShowAttachmentsForMeeting(id);
  };

  const navigateAction = (id: GridRowId, row: Meeting) => {
    const handleNavigate = (_id: GridRowId) => () => {
      onNavigate(row);
    };

    return ([
      <GridActionsCellItem
        icon={<TooltipIcon titleKey={'general.open'} icon={<SearchIcon />} />}
        label="Open"
        onClick={handleNavigate(id)}
        sx={{
          color: 'primary.main',
        }}
      />,
      <GridActionsCellItem
          icon={
            <TooltipIconWithBadge
              badgeContent={row.attachments}
              titleKey='dashboard.meetingAttachments'
              icon={< FileCopyIcon fontSize="small" />}
            />
          }
          label={t('meeting.attachments')}
          onClick={openAttachments(id)}
          sx={{
            color: 'primary.main',
          }}
          disabled={id == emptyMeetingRepresentation.id}
        />,
    
    ]);
  }

  const attachmentsColumn: GridColDef<any> = {
    field: 'attachments',
    headerName: 'dashboard.meetingAttachments',
    type: 'actions',
    cellClassName: 'actions',
    getActions: ({ id, row }) => {
      return [
        <GridActionsCellItem
          icon={
            <TooltipIconWithBadge
              badgeContent={row.attachments}
              titleKey='dashboard.meetingAttachments'
              icon={< FileCopyIcon fontSize="small" />}
            />
          }
          label={t('meeting.attachments')}
          onClick={openAttachments(id)}
          sx={{
            color: 'primary.main',
          }}
          disabled={id == emptyMeetingRepresentation.id}
        />,
      ];
    },
  }

  const getFreeMultipleSelectComponent = (params: GridRenderCellParams) => (
    <FreeMultipleSelect
      onValueSelected={(value) => {
        // console.info(`SELECTED ${JSON.stringify(value)}`);
        params.row.participants = value;
        //update meeting in state after adding participants
        // const existingMeeting = params.row.id ? meetings.find(i => i.id === params.row.id) : null;
        onMeetingUpserted(params.row);
        params.api.updateRows([{ id: params.row.id, data: params.row }]);
      }}
      options={params.row.participants}
      values={params.row.participants}
      placeholder={t('meeting.addParticipants')}
    />);

  const getFreeMultipleSelectComponentForTopRow = (params: GridRenderCellParams) => (
    <FreeMultipleSelect
      onValueSelected={(value) => {
        params.row.participants = value;
        params.api.updateRows([{ id: params.row.id, data: params.row }]);
      }}
      options={params.row.participants}
      values={params.row.participants}
      placeholder={t('meeting.addParticipants')}
    />
  );

  async function updateSortModel(newSortModel: GridSortModel) {
    localStorage.setItem(MEETING_SORT_FIELD, newSortModel[0]?.field);
    localStorage.setItem(MEETING_SORT_DIRECTION, newSortModel[0]?.sort);
  }

  const getProjectSingleSelectColumn = (saveUpsert: boolean) => {
    return {
      ...projectColumn,
      flex: 0.3,
      editable: false,
      cellClassName: 'scroll-overflow',
      renderCell: (params) => (

        <FreeSingleSelect
          onValueSelected={(value) => {
            params.row.project = value;
            if (params.row.id == emptyMeetingRepresentation.id) {
              setTopRowProject(value);
            }
            if (saveUpsert) {
              onMeetingUpserted(params.row);
            }
            params.api.updateRows([{ id: params.row.id, data: params.row }]);
          }}
          options={projects || []}
          value={projects.find(p => p.id === (params.row.project || topRowProject))}
          placeholder={t('meeting.project')}
          formatValues={(values) => {
            const valueIds = values.map(v => v.id ? v.id : v);

            return projects.filter(
              p => valueIds.includes(p.id)
            ).map(t => t.name)
          }}
          formatInputs={formatInputs}
          isOptionEqualToValue={(option, value) => option.id === value}
          disableFreeSolo={true}
        />
      )
    };
  }

  const formatInputs = (values: (Tag | string)[]): string[] => {
    return values.map(v => {
      if (typeof v === 'string') {
        return v;
      }
      return v.id;
    });
  };

  const getTagOptions = (project: Project) => {
    return project?.tags || [];
  }

  const getFreeMultipleSelectComponentForTags = (saveUpsert: boolean) => {
    return {
      ...defaultTagsColumn,
      editable: false,
      renderCell: (params) => (
        <FreeCheckboxMultipleSelect
          onValueSelected={(value) => {
            params.row.defaultTags = value;
            if (saveUpsert) {
              onMeetingUpserted(params.row);
            }
            params.api.updateRows([{ id: params.row.id, data: params.row }]);
          }}
          options={getTagOptions(projects.find(p => p.id === (params.row.project || topRowProject)))}
          values={(params.row.defaultTags || []).filter(t => projects.find(p => p.id === (params.row.project || topRowProject))?.tags?.map(t => t.id).includes(t))}
          placeholder={t('meeting.addDefaultTags')}
          formatValues={(values) => {
            const valueIds = values.map(v => v.id ? v.id : v);

            return projects.find(p => p.id === (params.row.project || topRowProject))?.tags.filter(
              t => valueIds.includes(t.id)
            ).map(t => t.name)
          }}
          formatInputs={formatInputs}
          isOptionEqualToValue={(option, value) => option.id === value}
          disableFreeSolo={true}
        />
      )
    };
  }

  const topColumns = [
    { ...addIcon, renderCell: () => (<AddIcon color='action' />) },
    nameColumn,
    dateColumn,
    {
      ...inviteesColumn,
      flex: 0.6,
      editable: false,
      cellClassName: 'scroll-overflow',
      renderCell: (params) => getFreeMultipleSelectComponentForTopRow(params)
    }
  ];

  if (projects.length) {
    topColumns.push(getProjectSingleSelectColumn(false));
    topColumns.push(getFreeMultipleSelectComponentForTags(false));
  }

  topColumns.push({
    ...statusColumn,
    valueFormatter: value => ''
  });

  const bottomColumns = [
    identifierColumn,
    nameColumn,
    dateColumn,
    {
      ...inviteesColumn,
      flex: 0.6,
      editable: false,
      renderCell: (params) => getFreeMultipleSelectComponent(params)
    }];

  if (projects.length) {
    bottomColumns.push(getProjectSingleSelectColumn(true));
    bottomColumns.push(getFreeMultipleSelectComponentForTags(true));
  }

  bottomColumns.push({
    ...statusColumn,
    valueFormatter: value => t(meetingStatusLabels[value])
  });
  // bottomColumns.push(attachmentsColumn);

  return (
    <Box>
      <CssBaseline />
      {showAttachmentsForMeeting
        && (
          <AttachmentsPane
            allowModifications={true}
            height='70vh'
            uploadPrefix={`meetings/${showAttachmentsForMeeting}/`}
            meetingId={showAttachmentsForMeeting}
            onClose={() => setShowAttachmentsForMeeting(undefined)}
            onAttachmentsCountChanged={(count, meetingId, _itemId) => setMeetings(meetings.map(m => m.id === meetingId ? { ...m, attachments: count } : m))}
          />
        )}
      <CrudGrid
        processRowUpdate={onMeetingUpserted}
        onDelete={onItemDeleted}
        rows={meetings}
        placeholderRow={placeholderRow}
        topColumns={topColumns}
        columns={bottomColumns}
        height='85vh'
        emptyRow={emptyMeetingRepresentation}
        extraActions={navigateAction}
        actionsHeaderKey=''
        modelType={MEETING}
        mandatoryColumnNames={[nameColumn.field, dateColumn.field]}
        sortField={localStorage.getItem(MEETING_SORT_FIELD)}
        sortDirection={localStorage.getItem(MEETING_SORT_DIRECTION) as GridSortDirection}
        updateSortModel={updateSortModel}
      />
    </Box>
  );
};

export default withApplicationInsights(MeetingsPage, "MeetingsPage");
