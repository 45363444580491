import { Box, Typography, Button, TextField, Tooltip } from "@mui/material";
import { FC, ReactElement, useContext, useState } from "react";
import { MeetingItemRepresentation } from "../models/meetingItemRepresentation";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ItemStatus from "./itemStatus";
import dateFormatter, { dateTimeFormatter } from "../util/dateFormatter";
import InfoIcon from '@mui/icons-material/Info';
import TaskIcon from '@mui/icons-material/Task';
import SignpostIcon from '@mui/icons-material/Signpost';
import SendIcon from '@mui/icons-material/Send';
import { AppContext } from "../models/applicationState";
import { Context } from "./context";
import { useMsal } from "@azure/msal-react";
import { MeetingItem, MeetingItemStatus } from "../models";

interface ItemEditingPanelProps {
    meetingItem: MeetingItemRepresentation
    open: boolean
    allowMetadataEditing?: boolean
    readOnly?: boolean
    onClose: () => Promise<void> | void
    onUpdate: (item: MeetingItemRepresentation) => Promise<void> | void
}

const SYSTEM_PREFIX = '===== ';

export const hasUserComments = (item: MeetingItem) => {
    if (item?.comments?.includes(SYSTEM_PREFIX)) {
        return (item.comments?.includes('\n'));
    }
    return item?.comments?.trim().length > 0;
}
const addCommentToItem = (item: MeetingItem, userFullName: string, comment: string) =>{
    addCommentToItemInternal('', item, userFullName, comment);
}

export const addSystemCommentToItem = (item: MeetingItem, userFullName: string, comment: string) =>{
    addCommentToItemInternal(SYSTEM_PREFIX, item, userFullName, comment);
}

const addCommentToItemInternal = (delimiter: string, item: MeetingItem, userFullName: string, comment: string) =>{
    if (item.comments) {
        item.comments += `\n`;
    } else {
        item.comments = '';
    }
    item.comments += `${delimiter}[${userFullName} - ${dateTimeFormatter.format(new Date())}]: ${comment}`;
}

export const ItemEditingPanel: FC<ItemEditingPanelProps> = (props: ItemEditingPanelProps): ReactElement => {
    const { t } = useTranslation();
    const [content, setContent] = useState(props.meetingItem.content)
    const [comment, setComment] = useState("");
    const { instance } = useMsal();
    const appContext = useContext<AppContext>(Context);
    const projects = appContext.state.invoker?.projects || [];
    const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.onClose();
    }

    const postComment = () => {
        addCommentToItem(props.meetingItem.data, userFullName, comment);
        setComment('');
        props.onUpdate(props.meetingItem);
    }

    const saveChanges = () => {
        props.meetingItem.content = content;
        if (comment.trim()) {
            addCommentToItem(props.meetingItem.data, userFullName, comment);
            setComment('');
        }
        props.onUpdate(props.meetingItem);
        props.onClose();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            PaperProps={{
                style: {
                    backgroundColor: 'white'
                }
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {t('item.editPanel')}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {/* item panel */}
                    <Box sx={{ flex: '1 1 0' }}>
                        <TextField
                            sx={{
                                flexGrow: 1,
                                mb: 2
                            }}
                            id="itemDescription"
                            label={t('item.content').toUpperCase()}
                            placeholder={props.meetingItem.content}
                            multiline
                            fullWidth={true}
                            autoFocus={true}
                            required={true}
                            rows={4}
                            value={content}
                            disabled={props.readOnly || !props.allowMetadataEditing}
                            onChange={(e) => setContent(e.target.value)}
                        />

                        <Box sx={{
                            display: 'inline-grid',
                            gridTemplateColumns: 'auto 1fr',
                            columnGap: 1,
                            alignItems: 'baseline'
                        }}>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.identifier')}:
                            </Typography>
                            <Typography variant="body2">
                                {props.meetingItem.index}
                            </Typography>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.category')}:
                            </Typography>
                            <Typography variant="body2">
                                {props.meetingItem.category}
                            </Typography>

                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.types')}:
                            </Typography>
                            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                                {props.meetingItem.task &&
                                    <Box>
                                        <Tooltip title={t('item.taskType')}>
                                            <TaskIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                }
                                {props.meetingItem.decision &&
                                    <Box>
                                        <Tooltip title={t('item.decisionType')}>
                                            <SignpostIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                }
                                {props.meetingItem.info &&
                                    <Box>
                                        <Tooltip title={t('item.informationType')}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                }

                            </Box>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.actor')}:
                            </Typography>
                            <Typography variant="body2">
                                {props.meetingItem.actor}
                            </Typography>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.dueDate')}:
                            </Typography>
                            <Typography variant="body2">
                                {props.meetingItem.dueDate ? dateFormatter.format(new Date(props.meetingItem.dueDate)) : t('item.notAvailable')}
                            </Typography>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('item.status')}:
                            </Typography>
                            <Box sx={{ fontSize: '0.875rem' }}>
                                {props.meetingItem.task ?
                                    <ItemStatus status={props.meetingItem.status} lastModifiedBy={props.meetingItem.actor} /> :
                                    <Box>{t('item.notAvailable')}</Box>
                                }
                            </Box>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('meeting.project')}:
                            </Typography>
                            <Typography variant="body2">
                                {
                                projects.find(
                                    p => props.meetingItem.project === p.id
                                  )?.name
                                }
                            </Typography>
                            <Typography variant="overline" sx={{ color: (theme) => theme.palette.secondary.main }}>
                                {t('meeting.tags')}:
                            </Typography>
                            <Typography variant="body2">
                                {
                                projects.find(p => p.id === props.meetingItem.project)?.tags.filter(
                                    t => props.meetingItem.tags.includes(t.id)
                                  ).map(t => t.name).join(', ')
                                }
                            </Typography>
                        </Box>
                    </Box>

                    {/* Comments panel */}
                    <Box sx={{ flex: '1 1 0' }}>
                        <TextField
                            sx={{
                                "& .MuiOutlinedInput-root.Mui-disabled": {
                                    backgroundColor: (theme) => theme.palette.background.paper
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: (theme) => theme.palette.primary.main
                                }
                            }}
                            variant="outlined"
                            id="itemComments"
                            // label={t('item.comments').toUpperCase()}
                            multiline
                            fullWidth={true}
                            disabled={true}
                            rows={11}
                            value={props.meetingItem.data.comments}
                        />
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                            gap: 1
                        }}>
                            <TextField
                                sx={{
                                    flexGrow: 1,
                                }}
                                id="addNewItemComment"
                                label={t('item.addComment')}
                                multiline
                                fullWidth={true}
                                rows={2}
                                value={comment}
                                disabled={props.readOnly}
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <IconButton
                                aria-label="post"
                                onClick={postComment}
                                disabled={props.readOnly || !comment.trim()}
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus disabled={props.readOnly} onClick={saveChanges}>
                    {t('item.saveChanges')}
                </Button>
            </DialogActions>
        </Dialog >
    );
}