import { Dispatch } from "react";
import { Actions } from "../actions/common";
import { MeetingItem } from "./meetingItem";
import { Meeting } from "./meeting";
import { CalendarEvent } from "./calendarEvent";
import { ApiUser } from "./user";
import { ProjectFilters } from "./project";

export interface AppContext {
    state: ApplicationState
    dispatch: Dispatch<Actions>
}

export interface ApplicationState {
    lists?: Meeting[]
    selectedList?: Meeting
    selectedItem?: MeetingItem
    selectedEvent?: CalendarEvent
    invoker?: ApiUser
    selectedProjectFilters?: ProjectFilters
}

export const getDefaultState = (): ApplicationState => {
    return {
        lists: undefined,
        selectedList: undefined,
        selectedItem: undefined,
        selectedEvent: undefined,
        invoker: undefined,
        selectedProjectFilters: undefined
    }
}

